import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const updateConsignment = async (data, deal_id, user) => {
  const consignment_object = {
    precio_consignacion: data.precio_consignacion,
    moneda: data.moneda,
    tipo_cambio: data.tipo_cambio,
    comision: data.comision,
    dias_consignacion: data.dias_consignacion,
    monto_comision: data.monto_comision,
  };

  const consignment = Object.filter(consignment_object, function (info) {
    return info !== undefined && info !== "";
  });

  try {
    const takeDocRef = doc(
      db,
      "companies",
      user?.company,
      "deals",
      deal_id,
      "data",
      "take"
    );
    await updateDoc(takeDocRef, { consignment, user_update_data: user });
  } catch (error) {
    console.error("Error actualizando la consignacion: ", error);
    return error;
  }
};
export const updateFieldConsignament = async (data, deal_id, user) => {
  try {
    const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id);
    await updateDoc(takeDocRef, { consignacion: data });
  } catch (error) {
    console.error("Error actualizando la consignacion: ", error);
    return error;
  }
};
