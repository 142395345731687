import React, { useEffect, useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { MdCancel, MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import PaymentTabComponent from "./PaymentTabComponent";
import AttachmentTabComponent from "./AttachmentTabComponent";
import AmountToPayTabComponent from "./AmountToPayTabComponent";
import DocumentationTabComponent from "./DocumentationTabComponent";
import HolderTabComponent from "./HolderTabComponent";
import { colors } from "../../../../styles/colors";
import {
  ServerErrorComponent,
  SpinnerFullScreenComponent,
} from "../../../../components";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import { BenefitsTabComponent } from "./BenefitsTabComponent";
import { ConsignmentTabComponent } from "./ConsignmentTabComponent";
import { teams } from "../../../../constants/teams";
import shortSnapshotFirebase from "../../../../utils/ShortSnapshotFirebase";

export default function TakeTabsComponent() {
  // const [deal, setDeal] = useState(null);
  const [isEditing, setisEditing] = React.useState(true);
  const [takeData, setTakeData] = React.useState([]);
  const [selectedType, setSelectedType] = useState("toma"); //
  const { deal_id } = useParams();
  const { user } = useUser();
  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "take"
  );

  const dataBasics = useFirebaseOnSnapshot(user?.company, deal_id, "basics");

  useEffect(() => {
    const callbackDeals = (data) => {
      setSelectedType(data?.consignacion ? "consignacion" : "toma");
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    return () => {
      unsubscribeDeals();
    };
  }, [deal_id, user?.company]);

  const toggleEditing = React.useCallback(() => {
    setisEditing((isEditing) => !isEditing);
  }, []);

  useEffect(() => {
    setTakeData(data);
  }, [data]);

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  const handleRadioChange = (e) => {
    if (user?.team === teams.PRICING || user.team === teams.ADMIN) {
      const value = e.target.value;
      setSelectedType(value);
    }
  };

  return (
    <>
      {loading ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {error ? (
            <ServerErrorComponent error={error} />
          ) : (
            <>
              <div className="row align-items-center justify-content-between">
                <div className="row d-flex d-md-none mb-3">
                  <p className="deal-title">Resultados</p>
                </div>
                <div className="col-12 col-md d-flex align-items-center justify-content-end">
                  {isEditing ? null : (
                    <Form className="me-3">
                      <Form.Group className="d-flex align-items-center">
                        <Form.Label className="me-2 mb-0 change-insp-text">
                          Cambiar tipo de operación:
                        </Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="Toma"
                            value="toma"
                            checked={selectedType === "toma"}
                            onChange={handleRadioChange}
                            inline
                          />
                          <Form.Check
                            type="radio"
                            label="Consignación"
                            disabled={dataBasics?.data?.permuta ? true : false}
                            value="consignacion"
                            checked={selectedType === "consignacion"}
                            onChange={handleRadioChange}
                            inline
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  )}
                  <div style={{ width: "30px", height: "30px" }}>
                    {isEditing ? (
                      <MdEdit
                        onClick={toggleEditing}
                        style={{
                          fontSize: "26px",
                          color: colors.primary.primary,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <MdCancel
                        onClick={toggleEditing}
                        style={{
                          fontSize: "26px",
                          color: colors.status.red,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <Tabs
                defaultActiveKey="1"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="1"
                  title={
                    selectedType === "toma" ? "Monto a pagar" : "Consignación"
                  }
                >
                  {selectedType === "toma" ? (
                    <AmountToPayTabComponent
                      disabled={isEditing}
                      takeData={takeData}
                      deal_id={deal_id}
                      toggleEditing={toggleEditing}
                      selectedType={selectedType}
                    />
                  ) : (
                    <ConsignmentTabComponent
                      disabled={isEditing}
                      deal_id={deal_id}
                      user={user}
                      takeData={takeData?.consignment}
                      selectedType={selectedType}
                      toggleEditing={toggleEditing}
                    />
                  )}
                </Tab>
                <Tab eventKey="2" title="Documentacion entregada">
                  <DocumentationTabComponent
                    disabled={isEditing}
                    takeData={takeData?.documentation}
                    user={user}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                <Tab eventKey="3" title="Titulares">
                  <HolderTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    setTakeData={setTakeData}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                <Tab eventKey="4" title="Pago">
                  <PaymentTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    setTakeData={setTakeData}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                <Tab eventKey="5" title="Adjuntos">
                  <AttachmentTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    setTakeData={setTakeData}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                {/* <Tab eventKey="6" title="Beneficios">
                  <BenefitsTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                  />
                </Tab> */}
              </Tabs>
            </>
          )}
        </>
      )}
    </>
  );
}
