import React, { useState, useEffect, useCallback } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import "../../../../styles/style/tabsStyle.css";
import { MdEdit, MdCancel } from "react-icons/md";
import InicialInfoComponent from "./virtualInspectionTabs/InicialInfoComponent";
import TechnicalCheckComponent from "./virtualInspectionTabs/TechnicalCheckComponent";
import AdditionalChecksComponent from "./virtualInspectionTabs/AdditionalChecksComponent";
import ExteriorCheckComponent from "./virtualInspectionTabs/ExteriorCheckComponent";
import InteriorCheckComponent from "./virtualInspectionTabs/InteriorCheckComponent";
import PhotoAttachmentComponent from "./virtualInspectionTabs/PhotoAttachmentComponent";
import { useForm } from "react-hook-form";
import { updateResultados } from "../../services/servicesInspect";
import toast from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";

export default function VirtualInspTabsComponent({ deal_id, dataCheck }) {
  const [isEditing, setisEditing] = useState(true);
  const { register, handleSubmit, setValue, getValues } = useForm();
  const [filesInspect, setfilesInspect] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [canSave, setCanSave] = useState(false);
  const { user } = useUser();
  const [photosComplete, setPhotosComplete] = useState(false); // Para validar fotos

  const toggleEditing = useCallback(() => {
    setisEditing((isEditing) => !isEditing);
  }, []);

  const validateTabs = () => {
    const initialValues = getValues([
      "ex_vehiculo_pasajeros",
      "situacion_gnc",
      "dano_estructural",
      "modificacion_deportiva",
      "ploteo",
    ]);
    const isInitialComplete = initialValues.every(
      (value) => value !== undefined && value !== ""
    );

    const technicalValues = getValues([
      "kilometraje",
      "combustion",
      "transmision",
      "motor",
      "tablero",
      "embrague",
      "caja_cambios",
      "frenos",
      "aire_acondicionado",
      "calefaccion",
      "motor_ruidos",
      "neumaticos_delanteros",
      "neumaticos_traseros",
    ]);
    const isTechnicalComplete = technicalValues.every(
      (value) => value !== undefined && value !== ""
    );

    const exteriorValues = getValues([
      "llantas_estado",
      "chapa_pintura_estado",
    ]);
    const isExteriorComplete = exteriorValues.every(
      (value) => value !== undefined && value !== ""
    );

    const interiorValues = getValues([
      "tapizados_estado",
      "cierre_centralizado_estado",
      "vidrios_estado",
    ]);
    const isInteriorComplete = interiorValues.every(
      (value) => value !== undefined && value !== ""
    );

    const additionalValues = getValues([
      "services_oficiales",
      "comentarios_adicionales",
    ]);
    const isAdditionalComplete = additionalValues.every(
      (value) => value !== undefined && value !== ""
    );

    return (
      isInitialComplete &&
      isTechnicalComplete &&
      isExteriorComplete &&
      isInteriorComplete &&
      isAdditionalComplete &&
      photosComplete // Valida fotos completas
    );
  };

  useEffect(() => {
    setCanSave(validateTabs());
  }, [activeKey, photosComplete]); // Incluye photosComplete

  const handleSelect = (key) => {
    if (key !== "1") {
      const initialValues = getValues([
        "ex_vehiculo_pasajeros",
        "situacion_gnc",
        "dano_estructural",
        "modificacion_deportiva",
        "ploteo",
      ]);
      const isInitialComplete = initialValues.every(
        (value) => value !== undefined && value !== ""
      );

      if (!isInitialComplete) {
        toast.error(
          "Por favor, complete toda la información en la pestaña de Información inicial."
        );
        return;
      }
    }

    if (key !== "2" && key !== "1") {
      const technicalValues = getValues([
        "kilometraje",
        "combustion",
        "transmision",
        "motor",
        "tablero",
        "embrague",
        "caja_cambios",
        "frenos",
        "aire_acondicionado",
        "calefaccion",
        "motor_ruidos",
        "neumaticos_delanteros",
        "neumaticos_traseros",
      ]);
      const isTechnicalComplete = technicalValues.every(
        (value) => value !== undefined && value !== ""
      );

      if (!isTechnicalComplete) {
        toast.error(
          "Por favor, complete toda la información en la pestaña de Check técnico."
        );
        return;
      }
    }

    if (key !== "3" && key !== "1" && key !== "2") {
      const exteriorValues = getValues([
        "llantas_estado",
        "chapa_pintura_estado",
      ]);
      const isExteriorComplete = exteriorValues.every(
        (value) => value !== undefined && value !== ""
      );

      if (!isExteriorComplete) {
        toast.error(
          "Por favor, complete toda la información en la pestaña de Check Exterior."
        );
        return;
      }
    }

    if (key !== "4" && key !== "1" && key !== "2" && key !== "3") {
      const interiorValues = getValues([
        "tapizados_estado",
        "cierre_centralizado_estado",
        "vidrios_estado",
      ]);
      const isInteriorComplete = interiorValues.every(
        (value) => value !== undefined && value !== ""
      );

      if (!isInteriorComplete) {
        toast.error(
          "Por favor, complete toda la información en la pestaña de Check Interior."
        );
        return;
      }
    }

    if (
      key !== "5" &&
      key !== "1" &&
      key !== "2" &&
      key !== "3" &&
      key !== "4"
    ) {
      const additionalValues = getValues([
        "services_oficiales",
        "comentarios_adicionales",
      ]);
      const isAdditionalComplete = additionalValues.every(
        (value) => value !== undefined && value !== ""
      );

      if (!isAdditionalComplete) {
        toast.error(
          "Por favor, complete toda la información en la pestaña de Check Adicionales."
        );
        return;
      }
    }

    if (
      key !== "6" &&
      key !== "1" &&
      key !== "2" &&
      key !== "3" &&
      key !== "4" &&
      key !== "5"
    ) {
      const photosValues = getValues([
        "exterior_frente",
        "exterior_lateral_acompante",
        "exterior_leteral_conductor",
        "exterior_motor",
        "exterior_techo",
        "exterior_trasera",
        "interior_baul",
        "interior_delantero",
        "interior_tablero",
        "interior_trasero",
        "diagonal_acompañante",
        "diagonal_conductor",
      ]);
      const isPhotosComplete = photosValues.every(
        (value) => value !== undefined && value !== ""
      );

      if (!isPhotosComplete) {
        toast.error("Por favor, complete toda la información de Fotos");
        return;
      }
    }

    setActiveKey(key);
  };

  const handleCheckboxChange = (allChecked) => {
    setPhotosComplete(allChecked); // Actualiza el estado de fotos completas
  };

  const sendDataResultados = (data) => {
    if (!canSave) {
      toast.error("Complete todos los campos requeridos antes de guardar.");
      return;
    }
    updateResultados(user?.company, deal_id, data, user, filesInspect);
    toast.success("Información guardada con éxito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };

  return (
    <>
      <form onSubmit={handleSubmit(sendDataResultados)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="mt-1"
        >
          <p className="deal-title">Inspección virtual</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "30px", height: "30px" }}>
              {isEditing ? (
                <MdEdit
                  onClick={toggleEditing}
                  style={{
                    fontSize: "26px",
                    color: colors.primary.primary,
                    cursor: "pointer",
                  }}
                />
              ) : (
                <MdCancel
                  onClick={toggleEditing}
                  style={{
                    fontSize: "26px",
                    color: colors.status.red,
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
            <Button
              hidden={isEditing}
              type="submit"
              style={{ marginLeft: "20px" }}
              disabled={!canSave}
            >
              Guardar cambios
            </Button>
          </div>
        </div>
        <Tabs
          activeKey={activeKey}
          onSelect={handleSelect}
          id="controlled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="1" title="Información inicial">
            <InicialInfoComponent
              disabled={isEditing}
              register={register}
              setValue={setValue}
              dataInfoInicial={dataCheck?.informacion_inicial}
            />
          </Tab>
          <Tab eventKey="2" title="Check técnico">
            <TechnicalCheckComponent
              disabled={isEditing}
              register={register}
              dataCheckTecnico={dataCheck?.check_tecnico}
              setValue={setValue}
            />
          </Tab>
          <Tab eventKey="3" title="Check Exterior">
            <ExteriorCheckComponent
              disabled={isEditing}
              register={register}
              dataCheckExterior={dataCheck?.check_exterior}
              setValue={setValue}
            />
          </Tab>
          <Tab eventKey="4" title="Check Interior">
            <InteriorCheckComponent
              disabled={isEditing}
              dataCheckInterior={dataCheck?.check_interior}
              register={register}
              setValue={setValue}
            />
          </Tab>
          <Tab eventKey="5" title="Check Adicionales">
            <AdditionalChecksComponent
              disabled={isEditing}
              register={register}
              dataCheckAdicionales={dataCheck?.check_adicionales}
              setValue={setValue}
            />
          </Tab>
          <Tab eventKey="6" title="Fotos">
            <PhotoAttachmentComponent
              disabled={isEditing}
              register={register}
              deal_id={deal_id}
              dataPhotos={dataCheck?.photos?.status}
              setfilesInspect={setfilesInspect}
              filesInspect={filesInspect}
              onCheckboxChange={handleCheckboxChange} // Pasa la función
            />
          </Tab>
        </Tabs>
      </form>
    </>
  );
}
