import React from "react";
import { Modal } from "react-bootstrap";
import PricingComponent from "../../../Pricing/components/PricingComponent";
import { useState } from "react";

export default function DealQuotesModalComponent({
  showPricingModal,
  handleClose,
  data,
  loading,
  latestKey,
}) {
  const [activeModalPricing, setActiveModalPricing] = useState(false);

  return (
    <Modal
      show={showPricingModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body style={{ display: activeModalPricing ? "none" : "block" }}>
        <p className="title-modal-price">Cotizaciones</p>
        <PricingComponent
          handleClose={handleClose}
          data={data}
          loading={loading}
          latestKey={latestKey}
          setActiveModalPricing={setActiveModalPricing}
        />
      </Modal.Body>
    </Modal>
  );
}
