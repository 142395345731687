import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { teams } from "../../../../constants/teams";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "8px",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  section: {
    flexDirection: "row",
    marginBottom: "10px",
  },
  column: {
    width: "50%",
    marginBottom: "5px",
  },
  table: {
    width: "100%",
    marginBottom: "20px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeader: {
    width: "16.66%",
    padding: "5px",
    fontSize: "11px",
    fontWeight: "extrabolds",
    textAlign: "center",
    flex: 1,
    borderBottom: "1px solid #864bfa",
    borderTop: "1px solid #864bfa",
  },
  tableCell: {
    width: "16.66%",
    padding: "5px",
    fontSize: "10px",
    textAlign: "center",
    flex: 1,
    borderBottom: "1px solid #864bfa",
  },
  text: {
    marginBottom: "10px",
    fontSize: "10px",
    color: "#31156d",
  },
});

// Componente para renderizar el PDF
const ExportPDFComponentPricing = ({
  tableData,
  detailsData,
  totalMandatoryDiscount,
  user,
}) => {
  const formatNumberWithDotSeparator = (numberString) => {
    if (!numberString) return ""; // Manejar el caso en que el valor sea null o undefined
    const number = parseInt(String(numberString).replace(/\./g, ""), 10); // Convertir a entero y eliminar los puntos
    if (isNaN(number)) return numberString; // Si no es un número válido, devolver el valor original
    return number.toLocaleString("es-ES"); // Formatear el número con el separador de miles
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Svg style={{ width: 580, height: 76 }} viewBox="0 0 595 76">
            <Path
              d="M595 0H0L0 49.0585C95.2041 66.6448 194.92 76 297.649 76C400.377 76 499.887 66.6655 595 49.1122L595 0Z"
              fill="#864BFA"
            />
            <Path
              fill="white"
              d="M289.578 38.0933C289.578 43.4079 285.779 45.6032 281.912 45.6032C278.045 45.6032 274.246 43.4079 274.246 38.0933V28.4756H276.798C277.391 28.4756 277.872 28.9612 277.872 29.5598V37.7624C277.872 40.9853 279.65 42.1704 281.912 42.1704C284.174 42.1704 285.953 40.9853 285.953 37.7624V29.5598C285.953 28.9612 286.434 28.4756 287.027 28.4756H289.578V38.0933V38.0933Z"
            />
            <Path
              fill="white"
              d="M320 45.1673H317.466C316.873 45.1673 316.392 44.6817 316.392 44.0831V43.1644C315.252 44.6454 313.543 45.6031 311.194 45.6031C306.567 45.6031 302.923 41.9268 302.923 36.8221C302.923 31.7173 306.566 28.041 311.194 28.041C313.543 28.041 315.252 29.0162 316.392 30.4972V28.4755H320V45.1673V45.1673ZM306.635 36.8207C306.635 39.7477 308.518 42.1703 311.625 42.1703C314.732 42.1703 316.614 39.8701 316.614 36.8207C316.614 33.7713 314.611 31.4711 311.625 31.4711C308.639 31.4711 306.635 33.8924 306.635 36.8207Z"
            />
            <Path
              fill="white"
              d="M302.047 42.048C301.786 41.5193 301.155 41.2987 300.625 41.5516C299.852 41.9202 299.008 42.1704 298.218 42.1704C296.767 42.1704 295.593 41.2987 295.593 39.051C295.593 39.051 295.593 32.706 295.593 32.6993C295.593 31.9662 294.84 31.7859 294.84 31.7859H300.138C300.732 31.7859 301.213 31.3003 301.213 30.7018V28.4756H295.593V25.4719C295.593 24.8733 295.112 24.3877 294.519 24.3877H291.967V39.1223C291.967 43.5653 294.16 45.6045 297.907 45.6045C299.978 45.6045 301.654 44.8553 302.983 43.9487L302.047 42.048V42.048Z"
            />
            <Path
              fill="white"
              d="M269.966 38.3154C270.719 38.1055 271.436 37.8715 272.096 37.6186L270.739 34.0136C270.19 34.2235 269.589 34.4212 268.95 34.5974C268.606 34.6929 268.252 34.783 267.891 34.8678L262.385 20.8017C262.196 20.3188 261.734 20 261.218 20H257.879C257.364 20 256.9 20.3175 256.711 20.8017L251.207 34.8611C250.171 34.6203 249.213 34.3351 248.361 34.0069L247 37.6105C247.86 37.9414 248.794 38.2333 249.787 38.4848L247.125 45.2845H250.467C250.986 45.2845 251.451 44.963 251.637 44.4761L253.205 40.3949C253.432 39.8044 253.269 39.3551 252.94 39.1197C255.014 39.4385 257.251 39.6053 259.557 39.6053C261.863 39.6053 263.948 39.4493 265.971 39.1493C265.674 39.3861 265.533 39.8098 265.73 40.36L267.311 44.4774C267.497 44.9644 267.963 45.2858 268.481 45.2858H271.967L269.306 38.4902C269.528 38.4337 269.748 38.3772 269.963 38.3167L269.966 38.3154ZM255.085 35.5081L259.24 24.6864C259.324 24.4658 259.633 24.4658 259.717 24.6864L263.88 35.5282C262.493 35.6735 261.044 35.7475 259.559 35.7475C258.075 35.7475 256.523 35.6641 255.085 35.5067V35.5081Z"
            />
            <Path
              fill="white"
              d="M257.072 51.0282V55.8815H256.31V51.0282H254.465V50.314H258.916V51.0282H257.071H257.072Z"
            />
            <Path
              fill="white"
              d="M259.898 50.1404C260.194 50.1404 260.427 50.3758 260.427 50.6744C260.427 50.973 260.194 51.2003 259.898 51.2003C259.602 51.2003 259.377 50.965 259.377 50.6744C259.377 50.3839 259.61 50.1404 259.898 50.1404ZM259.539 55.8814V52.1043H260.263V55.8814H259.539Z"
            />
            <Path
              d="M264.847 54.8055C264.637 55.4727 264.038 55.9986 263.166 55.9986C262.163 55.9986 261.283 55.2601 261.283 53.9809C261.283 52.7945 262.131 51.9861 263.073 51.9861C264.225 51.9861 264.871 52.7864 264.871 53.9648C264.871 54.0589 264.863 54.1531 264.855 54.2002H262.039C262.055 54.8754 262.537 55.3462 263.166 55.3462C263.796 55.3462 264.077 55.0086 264.217 54.5849L264.847 54.8041V54.8055ZM264.108 53.6043C264.092 53.0622 263.742 52.6385 263.08 52.6385C262.465 52.6385 262.092 53.1173 262.06 53.6043H264.106H264.108Z"
              fill="white"
            />
            <Path
              d="M266.614 55.8815H265.882V52.1043H266.598V52.6464C266.87 52.1756 267.313 51.9954 267.749 51.9954C268.644 51.9954 269.088 52.6464 269.088 53.4871V55.8828H268.356V53.6136C268.356 53.0876 268.139 52.6639 267.484 52.6639C266.908 52.6639 266.612 53.128 266.612 53.701V55.8841L266.614 55.8815Z"
              fill="white"
            />
            <Path
              d="M272.957 55.3072C272.779 55.669 272.382 55.9824 271.798 55.9824C270.701 55.9824 270.023 55.1027 270.023 53.9795C270.023 52.8564 270.739 52.0009 271.798 52.0009C272.459 52.0009 272.817 52.3304 272.941 52.6371V50.1943H273.665V55.1808C273.665 55.5426 273.696 55.817 273.704 55.8802H272.996C272.98 55.7941 272.957 55.5977 272.957 55.3704V55.3072V55.3072ZM271.868 55.3301C272.537 55.3301 272.949 54.7409 272.949 53.9634C272.949 53.1859 272.544 52.6519 271.875 52.6519C271.206 52.6519 270.77 53.2021 270.77 53.9795C270.77 54.757 271.167 55.3301 271.867 55.3301H271.868Z"
              fill="white"
            />
            <Path
              d="M275.891 53.7212L276.903 53.5719C277.128 53.5409 277.191 53.4226 277.191 53.2894C277.191 52.9208 276.95 52.6141 276.382 52.6141C275.869 52.6141 275.581 52.9356 275.534 53.3755L274.834 53.21C274.911 52.4877 275.558 51.9846 276.367 51.9846C277.488 51.9846 277.924 52.6289 277.924 53.3661V55.2587C277.924 55.5882 277.955 55.7846 277.971 55.8788H277.255C277.239 55.7846 277.216 55.6434 277.216 55.369C277.052 55.6367 276.679 55.9972 276.01 55.9972C275.247 55.9972 274.757 55.4631 274.757 54.8659C274.757 54.1906 275.247 53.814 275.893 53.7198L275.891 53.7212ZM277.191 54.2633V54.0911L276.047 54.2633C275.735 54.3104 275.502 54.4906 275.502 54.8282C275.502 55.1107 275.735 55.3784 276.108 55.3784C276.708 55.3784 277.19 55.0878 277.19 54.2633H277.191Z"
              fill="white"
            />
            <Path
              d="M283.783 55.3072C283.604 55.669 283.207 55.9824 282.623 55.9824C281.526 55.9824 280.849 55.1027 280.849 53.9795C280.849 52.8564 281.564 52.0009 282.623 52.0009C283.284 52.0009 283.643 52.3304 283.767 52.6371V50.1943H284.49V55.1808C284.49 55.5426 284.521 55.817 284.529 55.8802H283.821C283.805 55.7941 283.783 55.5977 283.783 55.3704V55.3072V55.3072ZM282.693 55.3301C283.363 55.3301 283.775 54.7409 283.775 53.9634C283.775 53.1859 283.369 52.6519 282.7 52.6519C282.031 52.6519 281.595 53.2021 281.595 53.9795C281.595 54.757 281.992 55.3301 282.692 55.3301H282.693Z"
              fill="white"
            />
            <Path
              d="M289.076 54.8055C288.865 55.4727 288.267 55.9986 287.395 55.9986C286.391 55.9986 285.511 55.2601 285.511 53.9809C285.511 52.7945 286.359 51.9861 287.302 51.9861C288.453 51.9861 289.1 52.7864 289.1 53.9648C289.1 54.0589 289.092 54.1531 289.084 54.2002H286.267C286.283 54.8754 286.766 55.3462 287.395 55.3462C288.024 55.3462 288.305 55.0086 288.445 54.5849L289.076 54.8041V54.8055ZM288.336 53.6043C288.32 53.0622 287.971 52.6385 287.308 52.6385C286.694 52.6385 286.321 53.1173 286.289 53.6043H288.335H288.336Z"
              fill="white"
            />
            <Path
              d="M293.038 53.7212L294.049 53.5719C294.275 53.5409 294.337 53.4226 294.337 53.2894C294.337 52.9208 294.096 52.6141 293.528 52.6141C293.015 52.6141 292.727 52.9356 292.68 53.3755L291.981 53.21C292.058 52.4877 292.704 51.9846 293.513 51.9846C294.635 51.9846 295.07 52.6289 295.07 53.3661V55.2587C295.07 55.5882 295.101 55.7846 295.117 55.8788H294.401C294.385 55.7846 294.363 55.6434 294.363 55.369C294.199 55.6367 293.825 55.9972 293.156 55.9972C292.394 55.9972 291.903 55.4631 291.903 54.8659C291.903 54.1906 292.394 53.814 293.039 53.7198L293.038 53.7212ZM294.337 54.2633V54.0911L293.194 54.2633C292.882 54.3104 292.648 54.4906 292.648 54.8282C292.648 55.1107 292.882 55.3784 293.255 55.3784C293.855 55.3784 294.336 55.0878 294.336 54.2633H294.337Z"
              fill="white"
            />
            <Path
              d="M297.567 55.9983C296.695 55.9983 296.166 55.3392 296.166 54.4823V52.1028H296.898V54.372C296.898 54.8899 297.131 55.3459 297.746 55.3459C298.36 55.3459 298.633 54.9531 298.633 54.3801V52.1028H299.365V55.1805C299.365 55.4791 299.389 55.7454 299.404 55.8799H298.704C298.688 55.7938 298.673 55.5813 298.673 55.4239C298.471 55.8167 298.004 55.9969 297.568 55.9969L297.567 55.9983Z"
              fill="white"
            />
            <Path
              d="M301.669 52.1043H302.478V52.7714H301.669V54.734C301.669 55.0797 301.809 55.2519 302.19 55.2519C302.283 55.2519 302.415 55.2357 302.478 55.2209V55.8491C302.415 55.872 302.228 55.9204 301.979 55.9204C301.341 55.9204 300.937 55.5276 300.937 54.8443V52.7714H300.221V52.1043H300.424C300.829 52.1043 300.999 51.8527 300.999 51.5232V50.9111H301.669V52.1043V52.1043Z"
              fill="white"
            />
            <Path
              d="M307.007 53.989C307.007 55.1431 306.214 56 305.093 56C303.972 56 303.179 55.1445 303.179 53.989C303.179 52.8335 303.972 51.9861 305.093 51.9861C306.214 51.9861 307.007 52.8416 307.007 53.989ZM306.261 53.989C306.261 53.1173 305.716 52.6465 305.093 52.6465C304.471 52.6465 303.925 53.1173 303.925 53.989C303.925 54.8606 304.471 55.3395 305.093 55.3395C305.716 55.3395 306.261 54.8687 306.261 53.989Z"
              fill="white"
            />
            <Path
              d="M308.331 54.6642C308.378 55.057 308.674 55.3704 309.203 55.3704C309.615 55.3704 309.841 55.135 309.841 54.8674C309.841 54.632 309.671 54.4517 309.359 54.3804L308.72 54.2392C308.136 54.1141 307.786 53.7132 307.786 53.1792C307.786 52.5349 308.386 51.9861 309.116 51.9861C310.144 51.9861 310.462 52.6613 310.54 52.999L309.893 53.2424C309.863 53.046 309.707 52.6143 309.115 52.6143C308.742 52.6143 308.492 52.8577 308.492 53.1173C308.492 53.3447 308.632 53.5101 308.92 53.5733L309.527 53.7065C310.204 53.8558 310.561 54.2715 310.561 54.8297C310.561 55.3879 310.117 56 309.192 56C308.164 56 307.728 55.3328 307.667 54.901L308.328 54.6656L308.331 54.6642Z"
              fill="white"
            />
            <Path
              d="M312.074 54.8281C312.378 54.8281 312.619 55.0716 312.619 55.3783C312.619 55.685 312.378 55.9204 312.074 55.9204C311.77 55.9204 311.537 55.685 311.537 55.3783C311.537 55.0716 311.77 54.8281 312.074 54.8281Z"
              fill="white"
            />
          </Svg>
        </View>
        {/* Datos */}
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.text}>
              Patente: {detailsData?.section1?.carPlate}
            </Text>
            <Text style={styles.text}>
              Marca: {detailsData?.section1?.brand}
            </Text>
            <Text style={styles.text}>
              Modelo: {detailsData?.section1?.model}
            </Text>
            <Text style={styles.text}>
              Versión: {detailsData?.section1?.version}
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>
              Combustible: {detailsData?.section1?.fuel?.value}
            </Text>
            <Text style={styles.text}>
              Puertas: {detailsData?.section1?.doors}
            </Text>
            <Text style={styles.text}>
              Kilometraje:{" "}
              {formatNumberWithDotSeparator(detailsData?.section1?.mileage)} km
            </Text>
            <Text style={styles.text}>
              Apto consignación: {detailsData?.results?.aptoConsignacion}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={styles.text}>
              ¿Listo para la venta?: {detailsData?.results?.readyForSale}
            </Text>
            <Text style={styles.text}>
              ¿Requiere reparación mecánica?:{" "}
              {detailsData?.results?.requiresMechanicalRepair}
            </Text>
            <Text style={styles.text}>
              ¿Requiere chapa y pintura?:{" "}
              {detailsData?.results?.requiresSheetMetalAndPaint}
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>
              Estado general del vehículo:{" "}
              {detailsData?.section7?.overallStatus?.values[0]}
            </Text>
            <Text style={styles.text}>
              Días de puesta a punto: {detailsData?.results?.daysOfSetUp}
            </Text>
            <Text style={styles.text}>
              Descuentos obligatorios: $
              {formatNumberWithDotSeparator(totalMandatoryDiscount)} (*)
            </Text>
          </View>
        </View>
        {/* Tabla */}
        <View>
          <View style={styles.tableRow}>
            <Text style={styles.tableHeader}>Observaciones</Text>
            <Text style={styles.tableHeader}>Respuesta</Text>
            <Text style={styles.tableHeader}>Comentario</Text>
            {user?.team === teams.ADMIN || user?.team === teams.PRICING ? (
              <Text style={styles.tableHeader}>Descuento</Text>
            ) : null}
          </View>
          {tableData.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item.title}</Text>
              <Text style={styles.tableCell}>{item.values}</Text>
              <Text style={styles.tableCell}>{item.comments || "-"}</Text>
              <Text style={styles.tableCell}>
                ${formatNumberWithDotSeparator(item.discount)}
                {item.mandatory === "Si" ? "*" : null}
              </Text>
            </View>
          ))}
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}>Descuentos totales:</Text>
            <Text style={styles.tableCell}>
              $
              {formatNumberWithDotSeparator(
                detailsData?.results?.totalDemerits
              )}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ExportPDFComponentPricing;
