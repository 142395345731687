import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import BackButtonComponent from "../../../components/BackButtonComponent";
import LongSnapshotFirebase from "../../../utils/LongSnapshotFirebase";
import { useUser } from "../../../context/UserContext";
import shortSnapshotFirebase from "../../../utils/ShortSnapshotFirebase";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import DealSectionsButtonsComponent from "./dealDashboard/DealSectionsButtonsComponent";
import DealActionsCardSectionComponent from "./dealDashboard/DealActionsCardSectionComponent";
import DealInfoCardSectionComponent from "./dealDashboard/DealInfoCardSectionComponent";
import DealQuotesCardSectionComponent from "./dealDashboard/DealQuotesCardSectionComponent";
import ButtonCloseDeal from "../../Menu/ButtonCloseDeal";
import "../../../styles/style/DealActionsSection.css";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";
import { ButtonHistoryDealComponent } from "./ButtonHistoryDealComponent";
import KeyImg from "../images/key.svg";
import Consignment from "../images/consignment-icon.svg";

export default function DealLayoutComponent() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const [deal, setDeal] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showSection, setShowSection] = useState(false);
  const { data, loading } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "basics"
  );

  let isPageWide = useMediaQuery("(min-width: 460px)");

  const toggleSection = () => {
    setShowSection(!showSection);
  };

  useEffect(() => {
    const callbackDeals = (data) => {
      setDeal(data);
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    const callbackUser = (user) => {
      setUserData(user);
    };
    const unsubscribeUser = LongSnapshotFirebase(
      user?.company,
      deal_id,
      "data",
      "user_data",
      callbackUser
    );
    return () => {
      unsubscribeUser();
      unsubscribeDeals();
    };
  }, [deal_id, user?.company]);

  return (
    <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
      {/* vista cuando esta en monitores de pc */}
      {isPageWide ? (
        <>
          <Row style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <Col xs={12} lg={4}>
              <BackButtonComponent />
            </Col>
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-row justify-content-center"
            >
              {data.permuta && (
                <img src={KeyImg} alt="key-img" className="key-img" />
              )}
              {deal?.consignacion ? (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 200 }}
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>Consignación</Popover.Body>
                    </Popover>
                  }
                >
                  <img
                    src={Consignment}
                    alt="consignment-icon"
                    className="me-2"
                    style={{ width: "30px", height: "30px" }}
                  />
                </OverlayTrigger>
              ) : null}{" "}
              <p className="d-flex align-items-center justify-content-center mb-0 owner-name">
                {data?.patente ? data?.patente + " - " : null} {userData?.name}
              </p>
            </Col>
            <Col xs={12} lg={4}>
              <div className="d-flex align-items-center justify-content-end ">
                {deal?.client_id && (
                  <ButtonHistoryDealComponent client_id={deal?.client_id} />
                )}
                {deal?.status?.status === "ABIERTO" && (
                  <ButtonCloseDeal deal_id={deal_id} user={user} />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              lg={4}
              style={
                !isPageWide ? { marginBottom: "8px" } : { marginBottom: "0px" }
              }
            >
              <DealActionsCardSectionComponent basics={data} deal={deal} />
            </Col>
            <Col
              xs={12}
              lg={4}
              style={
                !isPageWide ? { marginBottom: "8px" } : { marginBottom: "0px" }
              }
            >
              <DealInfoCardSectionComponent
                basics={data}
                isPageWide={isPageWide}
                loading={loading}
              />
            </Col>
            <Col xs={12} lg={4}>
              <DealQuotesCardSectionComponent
                user={user}
                isPageWide={isPageWide}
              />
            </Col>
          </Row>
        </>
      ) : (
        // vista cuando esta en un dispositivo mobile
        <>
          <Row style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <Col xs={6}>
              <BackButtonComponent />
            </Col>
            <Col xs={6}>
              {deal?.status?.status === "ABIERTO" ? (
                <ButtonCloseDeal deal_id={deal_id} user={user} />
              ) : null}
            </Col>
          </Row>
          <Row style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className=" mb-0 owner-name">
                {data?.patente ? data?.patente + " - " : null} {userData?.name}
              </p>
              <span onClick={toggleSection}>
                {showSection ? (
                  <MdVisibilityOff className="eye-icon" />
                ) : (
                  <MdVisibility className="eye-icon" />
                )}
              </span>
            </div>
          </Row>
          <div
            className={`transitioned-section ${
              showSection ? "" : "hidden-section"
            }`}
          >
            <Row>
              <Col
                xs={12}
                lg={4}
                style={
                  !isPageWide
                    ? { marginBottom: "8px" }
                    : { marginBottom: "0px" }
                }
              >
                <DealActionsCardSectionComponent basics={data} deal={deal} />
              </Col>
              <Col
                xs={12}
                lg={4}
                style={
                  !isPageWide
                    ? { marginBottom: "8px" }
                    : { marginBottom: "0px" }
                }
              >
                <DealInfoCardSectionComponent
                  basics={data}
                  isPageWide={isPageWide}
                  loading={loading}
                />
              </Col>
              <Col xs={12} lg={4}>
                <DealQuotesCardSectionComponent user={user} />
              </Col>
            </Row>
          </div>
        </>
      )}
      <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Col>
          <DealSectionsButtonsComponent isPageWide={isPageWide} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Outlet context={{ deal: deal, userData: userData }} />
        </Col>
      </Row>
    </div>
  );
}
