import React, { createContext, useContext, useEffect, useState } from "react";
import {
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase/config";
import { createUser, getUser } from "../services/AuthServices";

const authContext = createContext();
export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

export function AuthProvider({ children }) {
  const [userLoggedIn, setUserLoggedIn] = useState(
    JSON.parse(localStorage.getItem("userLoggedIn")) || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(!!userLoggedIn);
  const [loginCompany, setLoginCompany] = useState(
    localStorage.getItem("loginCompany") || null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loginWithGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken();
      localStorage.setItem("authToken", token);
      return { user: result.user, token };
    } catch (err) {
      setError(err);
      console.error("Error en login con Google:", err);
    }
  };

  const logout = () => {
    signOut(auth);
    localStorage.removeItem("authToken");
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("loginCompany");
    setUserLoggedIn(null);
    setIsAuthenticated(false);
  };

  const updateOrCreateUser = async (currentUser, loginCompany, token) => {
    try {
      const getUserData = await getUser({
        params: { user_id: currentUser.uid, company: loginCompany },
      });

      if (!getUserData.error) {
        const userData = {
          email: currentUser.email,
          name: getUserData.name,
          uid: currentUser.uid,
          company: loginCompany,
          companies: getUserData.companies,
          team: getUserData.team,
          token,
        };
        setUserLoggedIn(userData);
        localStorage.setItem("userLoggedIn", JSON.stringify(userData));
        localStorage.setItem("loginCompany", loginCompany);
      } else {
        // Si el usuario no existe, lo creamos
        const newUser = await createUser({
          user_id: currentUser.uid,
          company: loginCompany,
          name: currentUser.displayName || "Nuevo Usuario",
          email: currentUser.email,
          team: null,
        });

        const userData = {
          email: newUser.email,
          name: newUser.name,
          uid: newUser.user_id,
          company: newUser.company,
          team: "",
          token,
        };
        setUserLoggedIn(userData);
        localStorage.setItem("userLoggedIn", JSON.stringify(userData));
        localStorage.setItem("loginCompany", loginCompany);
      }
    } catch (error) {
      console.error("Error en la creación o actualización del usuario:", error);
      setError(error);
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);

      if (currentUser && loginCompany) {
        try {
          const token = storedToken || await currentUser.getIdToken(true);
          await updateOrCreateUser(currentUser, loginCompany, token);
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Error al actualizar el usuario:", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      } else {
        setUserLoggedIn(null);
        setIsAuthenticated(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [loginCompany, userLoggedIn?.team]);

  return (
    <authContext.Provider
      value={{
        userLoggedIn,
        logout,
        loginWithGoogle,
        isAuthenticated,
        setLoginCompany,
        loginCompany,
        loading,
        error,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
