import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import "../../../styles/style/cardLoginStyle.css";
import { toast } from "react-hot-toast";
import { colors } from "../../../styles/colors";

//Este componente contiene el boton para loguearse con la cuenta de Google.
const CardLoginComponent = ({ isDisabled }) => {
  const { loginWithGoogle, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, [isAuthenticated, navigate]);

  const handleGoogleSignin = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      toast.error(`Error al iniciar sesión: ${error.message}`, {
        style: { color: colors.text.primary, background: colors.error.dark },
      });
    }
  };

  return (
    <div className="card-login">
      <div className="d-flex flex-column justify-content-center align-items-center ">
        <p className="text-center title-login-card">Iniciar sesión</p>
        <Button
          className="d-flex justify-content-center align-items-center button-google"
          onClick={handleGoogleSignin}
          disabled={isDisabled}
        >
          <FaGoogle fontSize={24} className="me-2" />
          Ingresar con Google
        </Button>
      </div>
    </div>
  );
};

export default CardLoginComponent;
