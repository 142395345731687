import React, { useEffect, useState } from 'react'
import { getApiData, getDollarExchange, getEditor } from '../../services/sevicesPricingView';
import { Button, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { colors } from '../../../../styles/colors';
import { TbUserEdit } from 'react-icons/tb';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { MdInfo } from 'react-icons/md';
import { ImStatsBars } from 'react-icons/im';
import { RiPencilFill } from 'react-icons/ri';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { useUser } from '../../../../context/UserContext';
import PricingFilterButtonComponent from '../pricingModals/PricingFilterButtonComponent';
import PricingTableComponent from './PricingTableComponent';
import PricingEditCategoryModalComponent from '../pricingModals/PricingEditCategoryModalComponent';
import PricingEditStopperModalComponent from '../pricingModals/PricingEditStopperModalComponent';
import PricingEditPriceModalComponent from '../pricingModals/PricingEditPriceModalComponent';
import PricingQuoteSimulateModalComponent from '../pricingModals/PricingQuoteSimulateModalComponent';
import PricingEditExchangeRateModalComponent from '../pricingModals/PricingEditExchangeRateModalComponent';
import PricingImportAndExportModalComponent from '../pricingModals/PricingImportAndExportModalComponent';

export default function PricingViewTableComponent() {
  const sheetUrl = process.env.REACT_APP_SHEET_URL;
  let isPageWide = useMediaQuery("(min-width: 460px)");
  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryModal, setCategoryModal] = useState(false)
  const [stepperModal, setStepperModal] = useState(false)
  const [priceModal, setPriceModal] = useState(false)
  const [simulateModal, setSimulateModal] = useState(false)
  const [exchangeModal, setExchangeModal] = useState(false)
  const [archiveModal, setArchiveModal] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [carsPerPage, setCarsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(30);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dollarExchangeSale, setDollarSaleExchange] = useState()
  const [dollarAuta, setDollarAuta] = useState()
  const [editor, setEditor] = useState()
  const [selectedRows, setSelectedRows] = useState([]);
  const [showClearButton, setShowClearButton] = useState(false);
  const [isStopper, setIsStopper] = useState(false);
  const isAnyRowSelected = selectedRows.length > 0;
  const isSingleRowSelected = selectedRows.length === 1;
  const [filterValues, setFilterValues] = useState({
    stopper_filter: "",
    category_filter: "",
    brand_filter: "",
    year_filter: "",
    model_filter: "",
    version_filter: "",
    price_filter_min: 0,
    price_filter_max: 600041,
    order_by_prices: "",
    order_by_name: "",
    order_by_year: ""
  });

  const fetchData = async () => {

    try {
      if (!firstLoad && !searchTerm && !Object.values(filterValues).some(val => val !== "" && val !== 0)) {
        setLoading(true);
      }
      const responseData = await getApiData({
        endpoint: 'pricing/get_prices',
        search: searchTerm.toLowerCase(),
        page: currentPage,
        carsPerPage: carsPerPage,
        filterValues
      });

      setData(responseData.prices);
      setTotalPages(responseData?.pages_count);
      setLoading(false);
      setFirstLoad(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const fetchDataExchange = async () => {
    try {
      const responseData = await getDollarExchange({
        endpoint: 'pricing/dolar_exchange',
      });
      setDollarAuta(responseData?.dolar_auta);
      setDollarSaleExchange(responseData?.dolar_blue_venta);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLastEditor = async () => {
    try {
      const responseData = await getEditor({
        user_id: user?.uid,
      });
      setEditor(responseData)
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowClearButton(value.trim().length > 0);
  };

  const handleClearInput = () => {
    setSearchTerm('');
    setShowClearButton(false);
    setCurrentPage(1);
  };


  useEffect(() => {
    fetchData();
    setSelectedRows([]);
  }, [currentPage, carsPerPage, filterValues, searchTerm]);


  useEffect(() => {
    fetchDataExchange();
    fetchLastEditor();
  }, []);


  return (
    <>
      {loading ? (
        <div className="text-center h-100 d-flex ">
          <Spinner
            animation="border"
            variant="primary"
            data-testid="spinner"
            role="status"
            style={{
              margin: "auto",
            }}>
          </Spinner>
        </div>
      ) : (
        <>
          {isPageWide ? (
            <>
              <div className='d-flex justify-content-between align-items-center '>

                <OverlayTrigger
                  placement="right"
                  overlay={<Popover id="popover-stopper">
                    <Popover.Body>
                      {`Última modificación realizada por ${editor?.user_name} (${editor?.user_team}) el ${editor?.last_update}`}
                    </Popover.Body>
                  </Popover>}
                >
                  <div style={{}}>
                    <TbUserEdit style={{ color: colors.primary.primary }} size={20} />
                  </div>
                </OverlayTrigger>
                <div className="search-container">
                  <input type="text" className='input-search-pricing-view'
                    placeholder="Buscar por marca / modelo / versión / idAuta"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }} />
                  {showClearButton && (
                    <div className="search-clean-icon" onClick={handleClearInput}>
                      <FaTimes />
                    </div>
                  )}
                  <div className="search-icon-pricing" onClick={handleSearch}>
                    <FaSearch />
                  </div>
                </div>
                <Button disabled={!isAnyRowSelected} onClick={() => setStepperModal(true)}>Editar Stoppers</Button>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <Button
                    disabled={!isAnyRowSelected || isStopper}
                    onClick={() => setCategoryModal(true)}
                  >
                    Editar Categoría
                  </Button>
                  {isStopper && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Popover id="popover-stopper">
                        <Popover.Body>
                          Tu selección incluye un stopper SI,
                          <br />
                          no puedes cambiar su categoría.
                        </Popover.Body>
                      </Popover>}
                    >
                      <div style={{ position: 'absolute', top: -12, right: -16 }}>
                        <MdInfo style={{ color: colors.status.yellow, cursor: 'pointer' }} />
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <Button disabled={!isAnyRowSelected} onClick={() => setPriceModal(true)}>Editar Precio</Button>
                <Button disabled={!isSingleRowSelected ? true : false} onClick={() => setSimulateModal(true)}>Simular cotización</Button>
                <OverlayTrigger
                  placement="top"
                  overlay={<Popover id="popover-basic">
                    <Popover.Body>
                      Cotizacion de referencia
                    </Popover.Body>
                  </Popover>}>
                  <div className='container-tipo-cambio d-flex p-2' style={{ height: '40px' }}>
                    <ImStatsBars className='tc-ref-icon' />
                    <p className='tc-ref-text ms-1' style={{ marginBottom: '0px' }}>${Number(dollarExchangeSale).toLocaleString('es-AR')}</p>
                  </div>
                </OverlayTrigger>

                <div className='container-tipo-cambio'>
                  <p className='tc-text'>T.C:${Number(dollarAuta).toLocaleString('es-AR')}</p>
                  <Button onClick={() => setExchangeModal(true)}>
                    <RiPencilFill style={{ fontSize: "20px" }} />
                  </Button>
                </div>
                <Button>
                  <a
                    href={sheetUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                  >
                    Abrir en Sheets
                  </a>
                </Button>
                <PricingFilterButtonComponent
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  setCurrentPage={setCurrentPage} />
              </div></>
          ) : (
            <div>
              <div className='d-flex justify-content-between align-items-center'>
                <OverlayTrigger
                  placement="right"
                  overlay={<Popover id="popover-stopper">
                    <Popover.Body>
                      {`Última modificación realizada por ${editor?.user_name} (${editor?.user_team}) el ${editor?.last_update}`}
                    </Popover.Body>
                  </Popover>}
                >
                  <div className='mb-2'>
                    <TbUserEdit style={{ color: colors.primary.primary }} size={24} />
                  </div>
                </OverlayTrigger>
                <div className="search-container mb-2">
                  <input type="text" className='input-search-pricing-view'
                    placeholder="Buscar por marca / modelo / versión / idAuta"
                    value={searchTerm}
                    onChange={handleInputChange} />
                  <div className="search-icon-pricing" onClick={handleSearch}>
                    <FaSearch />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center  mb-2'>
                <Button style={{ fontSize: '14px' }} disabled={!isAnyRowSelected} onClick={() => setStepperModal(true)}>Editar Stoppers</Button>
                <Button style={{ fontSize: '14px' }} disabled={!isAnyRowSelected || isStopper} onClick={() => setCategoryModal(true)}>Editar Categoría</Button>
                <Button style={{ fontSize: '14px' }} disabled={!isAnyRowSelected} onClick={() => setPriceModal(true)}>Editar Precio</Button>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Button style={{ fontSize: '14px' }} disabled={!isAnyRowSelected} onClick={() => setSimulateModal(true)}>Simular cotización</Button>

                <div className='container-tipo-cambio' style={{ width: '100px' }}>
                  <p className='tc-text' style={{ fontSize: '13px' }}>T.C:${Number(dollarAuta).toLocaleString('es-AR')}</p>
                  <Button onClick={() => setExchangeModal(true)}>
                    <RiPencilFill style={{ fontSize: "15px" }} />
                  </Button>
                </div>
                <div className='container-tipo-cambio d-flex p-2' style={{ height: '40px' }} >
                  <ImStatsBars className='tc-ref-icon' />
                  <p className='tc-ref-text ms-1' style={{ marginBottom: '0px' }}>${Number(dollarExchangeSale).toLocaleString('es-AR')}</p>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Button style={{ fontSize: '14px' }} >
                  <a
                    href={sheetUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                  >
                    Abrir en Sheets
                  </a>
                </Button>

                <PricingFilterButtonComponent
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  setCurrentPage={setCurrentPage} />
              </div>
            </div>)}
          {loading ? (
            <div className="text-center mt-3 h-100 d-flex ">
              <Spinner
                animation="border"
                variant="primary"
                style={{
                  margin: "auto",
                }}
              ></Spinner>
            </div>
          ) : (
            searchTerm.length > 0 && data?.length === 0) ? (
            <div className="text-center mt-3">
              <p className='tc-text' style={{ fontSize: '18px' }}>Buscando...</p>
            </div>
          ) : (
            searchTerm.length === 0 && data?.length === 0 ? (
              <div className="text-center mt-3">
                <p className='tc-text' style={{ fontSize: '18px' }}>No hay resultados.</p>
              </div>
            ) : (
              <PricingTableComponent
                currentPage={currentPage}
                carsPerPage={carsPerPage}
                setCurrentPage={setCurrentPage}
                setCarsPerPage={setCarsPerPage}
                totalPages={totalPages}
                data={data}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setIsStopper={setIsStopper}
              />
            )
          )}

          <PricingEditCategoryModalComponent
            modal={categoryModal}
            setModal={setCategoryModal}
            selectedIds={selectedRows.map(row => row.idAuta)}
            fetchData={fetchData}
            userId={user?.uid}
            fetchLastEditor={fetchLastEditor}
          />
          <PricingEditStopperModalComponent
            modal={stepperModal}
            setModal={setStepperModal}
            selectedIds={selectedRows.map(row => row.idAuta)}
            stopperValue={selectedRows.map(row => row.stopper)}
            categoryValue={selectedRows.map(row => row.category)}
            dollarValue={selectedRows.map(row => row.usd.toLocaleString())}
            isSingleRowSelected={isSingleRowSelected}
            fetchData={fetchData}
            userId={user?.uid}
            fetchLastEditor={fetchLastEditor}

          />
          <PricingEditPriceModalComponent
            modal={priceModal}
            setModal={setPriceModal}
            selectedIds={selectedRows.map(row => row.idAuta)}
            isSingleRowSelected={isSingleRowSelected}
            fetchData={fetchData}
            setSelectedRows={setSelectedRows}
            userId={user?.uid}
            fetchLastEditor={fetchLastEditor}
          />
          <PricingQuoteSimulateModalComponent
            modal={simulateModal}
            setModal={setSimulateModal}
            data={selectedRows[0]}
          />
          <PricingEditExchangeRateModalComponent
            modal={exchangeModal}
            setModal={setExchangeModal}
            fetchDataExchange={fetchDataExchange}
            fetchData={fetchData}
          />
          <PricingImportAndExportModalComponent
            modal={archiveModal}
            setModal={setArchiveModal}
            data={data} setData={setData} />
        </>
      )}
    </>
  )
}
