import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { MdSms } from "react-icons/md";
import { colors } from "../../../../styles/colors";

const ToolTipComment = ({ data }) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id="popover-basic">
          <Popover.Body>{data}</Popover.Body>
        </Popover>
      }
    >
      <div
        className="icon-container-table ms-1 mb-2"
        style={{
          backgroundColor: colors.status.red,
        }}
      >
        <MdSms
          style={{
            color: "white",
          }}
          fontSize="12px"
        />
      </div>
    </OverlayTrigger>
  );
};

export default ToolTipComment;
