/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { MdEdit, MdCancel } from "react-icons/md";
import { useParams } from "react-router-dom";
import VehicleComponent from "../components/adminInfo/VehicleComponent";
import HolderComponentNew from "../components/adminInfo/HolderComponentNew";
import ReportsComponent from "../components/adminInfo/ReportsComponent";
import AttachedFilesComponents from "../components/adminInfo/AttachedFilesComponents";
import { useForm } from "react-hook-form";
import { updateDataAdmin } from "../services/servicesInfoAdmin";
import { useUser } from "../../../context/UserContext";
import { colors } from "../../../styles/colors";
import toast from "react-hot-toast";
import { HiCheckCircle } from "react-icons/hi";
import CommentAdditional from "../components/adminInfo/CommentsAdditional";
import { teams } from "../../../constants/teams";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";
import {
  ServerErrorComponent,
  SpinnerFullScreenComponent,
} from "../../../components";
import ComnetInformationComponent from "../components/adminInfo/ComnetInformationComponent";

export default function AdminInfoPage() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const [isEditing, setisEditing] = React.useState(true);
  const { register, handleSubmit, setValue } = useForm();
  const [datavehicle, setdatavehicle] = useState();
  const [dataIformes, setdataIformes] = useState();
  const [filesAdmin, setfilesAdmin] = useState([]);
  const [valueVersion, setvalueVersion] = useState();
  const [dataHolder, setdataHolder] = useState();
  const [dataObservaciones, setdataObservaciones] = useState();
  const [dataObservacionesAddittional, setdataObservacionesAddittional] =
    useState();
  const [dataComnet, setdataComnet] = useState();
  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "administrative"
  );
  const [dataUserUpdate, setdataUserUpdate] = useState();
  const [valueMunicipalizado, setValueMunicipalizado] = useState("");
  const [valueRadicacion, setValueRadicacion] = useState("");

  useEffect(() => {
    setdataHolder(data?.titulares);
    setdatavehicle(data?.vehicle_info);
    setdataIformes(data?.informes);
    setvalueVersion(data?.version);
    setdataObservaciones(data?.observaciones);
    setdataObservacionesAddittional(data?.observaciones_adicionales);
    setdataComnet(data?.informacion_comnet);
    setvalueVersion(data?.version === "final" ? true : false);
    setValue("version", data?.version === "final" ? true : false);
    setdataUserUpdate(data?.user_update_data);
  }, [data]);

  const toggleEditing = React.useCallback(() => {
    setisEditing((isEditing) => !isEditing);
  }, []);

  const updateInfoAdmin = (data) => {
    updateDataAdmin(deal_id, data, user, filesAdmin);
    toast.success("Informacion Guardada con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  const textStyleVersionSelected = {
    color: colors.primary.primary,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
  };

  const textStyleVersionNoSelected = {
    color: colors.disabled[100],
    fontWeight: "400",
    fontSize: "14px",
    marginBottom: "0px",
  };

  const textStyleUpdateDateUser = {
    color: colors.gris[1000],
    fontWeight: "400",
    fontSize: "12px",
    marginBottom: "0px",
  };

  return (
    <>
      {loading ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {error ? (
            <ServerErrorComponent error={error} />
          ) : (
            <div
              className="containerAll"
              style={{
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {valueVersion ? (
                  <div className="d-flex align-items-center">
                    <p className="deal-title">Informes Finales</p>
                    <HiCheckCircle
                      fontSize="21px"
                      className="mt-2 ms-1"
                      color={colors.primary.primary}
                    />
                  </div>
                ) : (
                  <p className="deal-title">Informes Preliminares</p>
                )}
                <div style={{ width: "30px", height: "30px" }}>
                  {isEditing ? (
                    <MdEdit
                      data-testid="edit-button"
                      onClick={
                        valueVersion === false ||
                          user?.team === teams.ADMIN ||
                          user?.team === teams.BACKOFFICE
                          ? toggleEditing
                          : null
                      }
                      style={{
                        fontSize: "26px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <MdCancel
                      onClick={toggleEditing}
                      data-testid="cancel-button"
                      style={{
                        fontSize: "26px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="mt-2">
                <p style={textStyle} className="mb-2">
                  Versión
                </p>
                <Form>
                  <div className="d-flex ">
                    <p
                      style={
                        !valueVersion
                          ? textStyleVersionSelected
                          : textStyleVersionNoSelected
                      }
                    >
                      Preliminar
                    </p>
                    <Form.Check
                      disabled={
                        !isEditing &&
                          (user?.team === teams.ADMIN ||
                            user?.team === teams.BACKOFFICE)
                          ? false
                          : true
                      }
                      type="switch"
                      id="custom-switch"
                      {...register("version")}
                      onChange={(e) => {
                        setvalueVersion(e.target.checked);
                      }}
                      className="ms-2"
                    />
                    <p
                      style={
                        valueVersion
                          ? textStyleVersionSelected
                          : textStyleVersionNoSelected
                      }
                    >
                      Final
                    </p>
                  </div>
                  {dataUserUpdate?.update_date ? (
                    <p style={textStyleUpdateDateUser} className="mt-1">
                      Última modificación realizada por {dataUserUpdate?.name} (
                      {dataUserUpdate?.team}) el{" "}
                      {dataUserUpdate?.update_date?.day} a las{" "}
                      {dataUserUpdate?.update_date?.hours}:
                      {dataUserUpdate?.update_date?.minutes} hs.
                    </p>
                  ) : null}
                </Form>
              </div>
              <div className="divider" style={{ marginTop: "16px" }} />
              <form onSubmit={handleSubmit(updateInfoAdmin)}>
                <VehicleComponent
                  disabled={isEditing}
                  register={register}
                  datavehicle={datavehicle}
                  setValue={setValue}
                  user={user}
                  textStyle={textStyle}
                  setValueMunicipalizado={setValueMunicipalizado}
                  setValueRadicacion={setValueRadicacion}
                />
                <div className="divider" style={{ marginTop: "16px" }} />
                <HolderComponentNew
                  disabled={isEditing}
                  register={register}
                  setValue={setValue}
                  dataHolder={dataHolder}
                  textStyle={textStyle}
                />
                <div className="divider" style={{ marginTop: "16px" }} />
                <ReportsComponent
                  disabled={isEditing}
                  register={register}
                  dataIformes={dataIformes}
                  user={user}
                  deal_id={deal_id}
                  setValue={setValue}
                  textStyle={textStyle}
                  valueMunicipalizado={valueMunicipalizado}
                  valueRadicacion={valueRadicacion}
                />
                <div className="divider" style={{ marginTop: "16px" }} />
                <ComnetInformationComponent
                  disabled={isEditing}
                  register={register}
                  dataComnet={dataComnet}
                  user={user}
                  deal_id={deal_id}
                  setValue={setValue}
                  textStyle={textStyle}
                />
                <div className="divider" style={{ marginTop: "16px" }} />
                <AttachedFilesComponents
                  disabled={isEditing}
                  setfilesAdmin={setfilesAdmin}
                  filesAdmin={filesAdmin}
                />
                <div className="divider" style={{ marginTop: "10px" }} />
                <CommentAdditional
                  disabled={isEditing}
                  register={register}
                  setValue={setValue}
                  dataObservaciones={dataObservaciones}
                  dataObservacionesAddittional={dataObservacionesAddittional}
                  user={user}
                />
                <Container style={{ height: "40px" }}>
                  <Button
                    hidden={isEditing}
                    style={{ float: "right" }}
                    type="submit"
                  >
                    Guardar cambios
                  </Button>
                </Container>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
}
