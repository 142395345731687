import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import LoginPage from "../modules/Login/LoginPage";
import CommentsPage from "../modules/Comments/CommentsPage";
import TechnicalSheetPage from "../modules/Technical-sheet/TechnicalSheetPage";
import PreTakeInspPage from "../modules/Deal/pages/PreTakeInspPage";
import InspectionPage from "../modules/Deal/pages/InspectionPage";
import AdminInfoPage from "../modules/Deal/pages/AdminInfoPage";
import { ProtectedRoute } from "./ProtectedRoute";
import AnaliticsPage from "../modules/Analitics/AnaliticsPage";
import StatesPage from "../modules/States/StatesPage";
import TakePage from "../modules/Deal/pages/TakePage";
import BasicInfoPage from "../modules/Deal/pages/BasicInfoPage";
import FakePage from "../modules/Deal/pages/FakePage";
import CheckViewPage from "../modules/Main/Pages/CheckViewPage";
import TakeViewPage from "../modules/Main/Pages/TakeViewPage";
import TasksViewPage from "../modules/Main/Pages/TasksViewPage";
import IncomingLeadsViewPage from "../modules/Main/Pages/IncomingLeadsViewPage";
import PendingReportsViewPage from "../modules/Main/Pages/PendingReportsViewPage";
import { MainLayoutComponent } from "../modules/Main/components";
import InitialRouteBasedOnRole from "./InitialRouteBasedOnRole";
import DealLayoutComponent from "../modules/Deal/components/DealLayoutComponent";
import GraphicsPage from "../modules/Main/Pages/GraphicsPage";
import PricingViewPage from "../modules/Main/Pages/PricingViewPage";
import UsersViewPage from "../modules/Main/Pages/UsersViewPage";
import LoginWithCompany from "../modules/Login/LoginWithCompany";
import GraphicsInspectionPage from "../modules/Main/Pages/GraphicsInspectionPage";
import GraphicsPricingPage from "../modules/Main/Pages/GraphicsPricingPage";
import GraphicsAcquisitionsPage from "../modules/Main/Pages/GraphicsAcquisitionsPage";
import GraphicsAnuraPage from "../modules/Main/Pages/GraphicsAnuraPage";
import UnauthorizedPage from "../modules/Main/Pages/UnauthorizedPage";
import { teams } from "../constants/teams";
import GraphicsMarketingPage from "../modules/Main/Pages/GraphicsMarketingPage";
import { useUser } from "../context/UserContext";



export default function AppRouter() {
  const { user } = useUser();
  function isAuthorizedUser(email) {
    const authorizedEmails = [
      'santiago.bensadon@auta.com.ar',
      'emorales@auta.com.ar',
      'facu.higa.tablet2001@gmail.com'
    ];
    return authorizedEmails.includes(email);
  }
  return (
    <BrowserRouter>
      <Routes>
        {/* rutas principales */}
        <Route path="/login" element={<LoginPage />} />
        <Route exact path="/login/:company" element={<LoginWithCompany />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route
          element={
            <ProtectedRoute>
              <MainLayoutComponent />
            </ProtectedRoute>
          }
        >
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <InitialRouteBasedOnRole />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tasks"
            element={
              <ProtectedRoute>
                <TasksViewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricing"
            element={
              isAuthorizedUser(user?.email) || user?.team === teams.PRICING ? (
                <PricingViewPage />
              ) : (
                <Navigate to="/unauthorized" />
              )
            }
          />
          <Route
            path="/incoming-leads"
            element={
              <ProtectedRoute>
                <IncomingLeadsViewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pending-reports"
            element={
              <ProtectedRoute>
                <PendingReportsViewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inspections"
            element={
              <ProtectedRoute>
                <CheckViewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/takes"
            element={
              <ProtectedRoute>
                <TakeViewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <UsersViewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/graphics"
            element={
              <ProtectedRoute>
                <GraphicsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/graphics-insp"
            element={
              <ProtectedRoute>
                <GraphicsInspectionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/graphics-pricing"
            element={
              <ProtectedRoute>
                <GraphicsPricingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/graphics-acquisitions"
            element={
              <ProtectedRoute>
                <GraphicsAcquisitionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/graphics-anura"
            element={
              <ProtectedRoute>
                <GraphicsAnuraPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/graphics-marketing"
            element={
              <ProtectedRoute>
                <GraphicsMarketingPage />
              </ProtectedRoute>
            }
          />
          <Route
            element={
              <ProtectedRoute>
                <DealLayoutComponent />
              </ProtectedRoute>
            }
          >
            <Route
              path="states/:deal_id"
              element={
                <ProtectedRoute>
                  <StatesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="deal/:deal_id"
              element={
                <ProtectedRoute>
                  <BasicInfoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="inspection/:deal_id"
              element={
                <ProtectedRoute>
                  <InspectionPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin-info/:deal_id"
              element={
                <ProtectedRoute>
                  <AdminInfoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="pre-take-insp/:deal_id"
              element={
                <ProtectedRoute>
                  <PreTakeInspPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="take/:deal_id"
              element={
                <ProtectedRoute>
                  <TakePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="pricing/:deal_id"
              element={
                <ProtectedRoute>
                  {/* <AnaliticsPage /> */}
                </ProtectedRoute>
              }
            />
            <Route
              path="analitics/:deal_id"
              element={
                <ProtectedRoute>
                  <AnaliticsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fake/:deal_id"
              element={
                <ProtectedRoute>
                  <FakePage />
                </ProtectedRoute>
              }
            />
            <Route path="comments/:deal_id" element={<CommentsPage />} />
            <Route
              path="tecnicalsheet/:deal_id"
              element={<TechnicalSheetPage />}
            />
            <Route path="*" element={<h1>Pagina no encontrada</h1>}></Route>
          </Route>
        </Route>


      </Routes>
    </BrowserRouter>
  );
}
