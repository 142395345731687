import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import DiscountsModalInput from "./DiscountsModalInput";
import { MdEdit, MdVisibility, MdDelete } from "react-icons/md";

export default function DiscountsTableComponent({
  formDataTableView,
  setFormDataTableView,
  handleLinkClick,
  editAllowed,
  setRenderedData,
  totalDiscount,
  setTotalDiscount,
  totalMandatoryDiscount,
  setTotalMandatoryDiscount,
  saveChanges,
}) {
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editSection, setEditSection] = useState(null);
  const [editKey, setEditKey] = useState(null);
  const [isNewObservation, setIsNewObservation] = useState(false);
  const [hasPendingSave, setHasPendingSave] = useState(false);

  useEffect(() => {
    const data = Object.entries(formDataTableView).flatMap(
      ([section, sectionData]) => {
        return Object.entries(sectionData)
          .flatMap(([key, data]) => {
            if (Array.isArray(data)) {
              const category = typeof data[0] === "string" ? data[0] : "";
              return data
                .slice(1)
                .filter((item) => shouldShowRow(item.values))
                .map((item, index) => ({
                  section,
                  key,
                  category,
                  title: item.title,
                  values: Array.isArray(item.values)
                    ? item.values.join(", ")
                    : item.values,
                  discount: parseInt(item.discount.replace(/[^0-9]/g, ""), 10),
                  mandatory: item.mandatory,
                  comments: item.comments,
                  anchor: item.anchor,
                }));
            } else if (typeof data === "object" && shouldShowRow(data.values)) {
              return {
                section,
                key,
                category: "",
                title: data.title,
                values: Array.isArray(data.values)
                  ? data.values.join(", ")
                  : data.values,

                discount: parseInt(data?.discount?.replace(/[^0-9]/g, ""), 10),
                mandatory: data.mandatory,
                comments: data.comments,
                anchor: data.anchor,
              };
            }
            return null;
          })
          .filter((item) => item !== null);
      }
    );
    setRenderedData(data);
    const total = data.reduce((sum, item) => {
      if (!isNaN(item.discount)) {
        return sum + item.discount;
      }
      return sum;
    }, 0);
    setTotalDiscount(total);

    const mandatoryTotal = data.reduce((sum, item) => {
      if (item.mandatory === "Si") {
        return sum + item.discount;
      }
      return sum;
    }, 0);
    setTotalMandatoryDiscount(mandatoryTotal);
  }, [
    formDataTableView,
    setRenderedData,
    setTotalDiscount,
    setTotalMandatoryDiscount,
  ]);
  const handleSave = (newData) => {

    if (editSection && editKey) {
      setFormDataTableView((prevFormData) => {
        const sectionData = prevFormData[editSection][editKey];
        if (Array.isArray(sectionData)) {
          const index = sectionData.findIndex(
            (item) => item.title === newData.title
          );
          const updatedArray = [...sectionData];
          updatedArray[index] = { ...updatedArray[index], ...newData };
          return {
            ...prevFormData,
            [editSection]: {
              ...prevFormData[editSection],
              [editKey]: updatedArray,
            },
          };
        } else {
          return {
            ...prevFormData,
            [editSection]: {
              ...prevFormData[editSection],
              [editKey]: {
                ...sectionData,
                ...newData,
              },
            },
          };
        }
      });
    } else {
      setFormDataTableView((prevFormData) => ({
        ...prevFormData,
        observations: {
          ...prevFormData.observations,
          [new Date().getTime()]: newData,
        },
      }));
    }
    resetEdit();
    setHasPendingSave(true);
  };

  useEffect(() => {
    if (hasPendingSave) {
      saveChanges();
      setHasPendingSave(false); // Restablecer hasPendingSave a false después de guardar
    }
  }, [formDataTableView, hasPendingSave]);
  const handleEdit = (section, key, index = null) => {
    const data = formDataTableView[section][key];
    let itemData;

    if (Array.isArray(data)) {
      if (index !== null) {
        itemData = {
          title: data[index + 1].title,
          values: data[index + 1].values,
          comments: data[index + 1].comments,
          discount: data[index + 1].discount,
          mandatory: data[index + 1].mandatory,
          anchor: data[index + 1].anchor,
        };
      }
    } else {
      itemData = {
        title: data.title,
        values: data.values,
        comments: data.comments,
        discount: data.discount,
        mandatory: data.mandatory,
        anchor: data.anchor,
      };
    }

    setEditData(itemData);
    setEditSection(section);
    setEditKey(key);
    setModal(true);
  };
  const handleDelete = (section, key, index = null) => {
    setFormDataTableView((prevFormData) => {
      const updatedData = { ...prevFormData };
      if (section === "observations") {
        delete updatedData["observations"][key];
      } else {
        if (Array.isArray(updatedData[section][key])) {
          const itemIndex = index !== null ? index + 1 : null;
          if (itemIndex !== null && updatedData[section][key][itemIndex]) {
            updatedData[section][key][itemIndex].values = ["Ok"];
            updatedData[section][key][itemIndex].comments = "";
            updatedData[section][key][itemIndex].discount = "";
            updatedData[section][key][itemIndex].mandatory = "";
          }
        } else {
          updatedData[section][key].values = ["Ok"];
          updatedData[section][key].comments = "";
          updatedData[section][key].discount = "";
          updatedData[section][key].mandatory = "";
        }
      }
      return updatedData;
    });
    saveChanges(); // este funciona bien al borrar 
  };

  const handleView = (section, anchor) => {
    let tapKey = parseInt(section.match(/\d+/)[0]);
    if (handleLinkClick) {
      handleLinkClick(tapKey, anchor);
    } else {
      console.error("handleLinkClick function is not provided.");
    }
  };

  const resetEdit = () => {
    setEditData(null);
    setEditSection(null);
    setEditKey(null);
    setIsNewObservation(false);
    setModal(false);
  };

  const handleAddDiscount = () => {
    resetEdit();
    setIsNewObservation(true);
    setModal(true);
  };
  const renderRows = () => {
    return Object.entries(formDataTableView).flatMap(
      ([section, sectionData]) => {
        return Object.entries(sectionData).flatMap(([key, data]) => {
          if (Array.isArray(data)) {
            const category = typeof data[0] === "string" ? data[0] : "";
            return data.slice(1).map((item, index) => {
              if (shouldShowRow(item.values)) {
                return (
                  <tr key={`${section}-${key}-${index}`}>
                    <td className="table-body">
                      {category ? `${category} [${item.title}]` : item.title}
                    </td>
                    <td className="table-body">{getItemValues(item.values)}</td>
                    <td className="table-body">{item.discount}</td>
                    <td className="table-body">{item.mandatory}</td>
                    <td className="table-body">{item.comments || "-"}</td>
                    {editAllowed ? (
                      <td className="table-body">
                        <MdEdit
                          size={18}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEdit(section, key, index)}
                        />
                        {item.anchor && (
                          <MdVisibility
                            size={18}
                            onClick={() => handleView(section, item.anchor)}
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          />
                        )}
                        <MdDelete
                          size={18}
                          onClick={() => handleDelete(section, key, index)}
                          style={{
                            marginLeft: "10px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                    ) : null}
                  </tr>
                );
              }
              return null;
            });
          } else if (typeof data === "object" && shouldShowRow(data.values)) {
            return (
              <tr key={`${section}-${key}`}>
                <td className="table-body">{data.title}</td>
                <td className="table-body">{getItemValues(data.values)}</td>
                <td className="table-body">{data.discount}</td>
                <td className="table-body">{data.mandatory}</td>
                <td className="table-body">{data.comments || "-"}</td>
                {editAllowed ? (
                  <td className="table-body">
                    <MdEdit
                      style={{ cursor: "pointer" }}
                      size={18}
                      onClick={() => handleEdit(section, key)}
                    />
                    {data?.anchor && (
                      <MdVisibility
                        size={18}
                        onClick={() => handleView(section, data?.anchor)}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      />
                    )}
                    <MdDelete
                      size={18}
                      onClick={() => handleDelete(section, key)}
                      style={{
                        marginLeft: "10px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                ) : null}
              </tr>
            );
          }
          return null;
        });
      }
    );
  };

  const shouldShowRow = (values) => {
    if (Array.isArray(values)) {
      return values.some((value) => value !== "" && value !== "Ok");
    }
    return values !== "Ok" && values !== "";
  };

  const getItemValues = (values) => {
    if (Array.isArray(values)) {
      return values.join(", ");
    }
    return values;
  };
  const formatNumberWithDotSeparator = (numberString) => {
    if (!numberString) return ""; // Manejar el caso en que el valor sea null o undefined
    const number = parseInt(String(numberString).replace(/\./g, ""), 10); // Convertir a entero y eliminar los puntos
    if (isNaN(number)) return numberString; // Si no es un número válido, devolver el valor original
    return number.toLocaleString("es-ES"); // Formatear el número con el separador de miles
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <p className="title-discount">Descuentos: </p>
            <p className="total-discount">
              Descuento total ${formatNumberWithDotSeparator(totalDiscount)} /
              Descuento obligatorio $
              {formatNumberWithDotSeparator(totalMandatoryDiscount)}
            </p>
          </div>
          <Button
            onClick={handleAddDiscount}
            style={{ marginLeft: "20px" }}
            disabled={!editAllowed}
          >
            Agregar descuento
          </Button>
        </div>
        <Table responsive bordered className="discount-table">
          <thead>
            <tr>
              <th className="table-header">Observaciones</th>
              <th className="table-header">Respuesta</th>
              <th className="table-header">Descuento</th>
              <th className="table-header">Obligatorio</th>
              <th className="table-header">Comentarios</th>
              {editAllowed ? <th className="table-header"></th> : null}
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </Table>
      </div>
      <DiscountsModalInput
        modal={modal}
        setModal={setModal}
        handleSave={handleSave}
        editData={editData}
        editSection={editSection}
        isNewObservation={isNewObservation}
      />
    </>
  );
}
