import React, { useState } from "react";
import { Table, Row, Col, Button, Form } from "react-bootstrap";
import InputComponent from "../../../../components/InputComponent";
import { MdAdd, MdEdit } from "react-icons/md";
import "../../../../styles/style/tabsStyle.css";
import "../../../../styles/style/tableStyle.css";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FinalConsumerModalComponent from "./FinalConsumerModalComponent";
import LegalEntityModalComponent from "./LegalEntityModalComponent";
import ModalEditFinalConsumerComponent from "./ModalEditFinalConsumerComponent";
import ModalEditLegalEntityComponent from "./ModalEditLegalIntityComponent";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import {
  deletePersonaJuridica,
  deleteTitular,
  sendApoderado,
  sendTitular,
  updateDataTake,
} from "../../services/TakeServices/HolderServices";

export default function HolderComponent({
  disabled,
  deal_id,
  setarrayConsumidorFinal,
  arrayConsumidorFinal,
  setarrayConsumidorFinalDelete,
  arrayConsumidorFinalDelete,
  arrayPersonaJuridicaDelete,
  setarrayPersonaJuridicaDelete,
  setarrayPersonaJuridica,
  arrayPersonaJuridica,
  dataEmpresa,
  dataTipoInscripcion,
  user,
  toggleEditing,
  textStyle,
}) {
  const { register, setValue, handleSubmit } = useForm();

  const [finalConsumerModal, setFinalConsumerModal] = React.useState(false);
  const [finalConsumerModalEdit, setfinalConsumerModalEdit] = useState(false);
  const [legalEntityModal, setLegalEntityModal] = React.useState(false);
  const [legaEntityModalEdit, setlegaEntityModalEdit] = useState();
  const [titularEdit, settitularEdit] = useState();
  const [titularId, settitularId] = useState();
  const [apoderadoEdit, setapoderadoEdit] = useState();
  const [apoderadoId, setapoderadoId] = useState();
  const [holderType, setHolderType] = useState();

  React.useEffect(() => {
    setValue(
      "tipo_inscripcion",
      dataTipoInscripcion !== "" ? dataTipoInscripcion : "Persona juridica"
    );
    setValue("validada", dataEmpresa?.validada);
    setValue("tipo_empresa", dataEmpresa?.tipo_empresa);
    setValue("domicilio_fiscal", dataEmpresa?.domicilio_fiscal);
    setHolderType(dataTipoInscripcion);
  }, [dataTipoInscripcion, dataEmpresa, setValue]);

  const updateInfoTake = (data) => {
    updateDataTake(user, deal_id, data);
    sendTitular(user, user?.company, deal_id, arrayConsumidorFinal);
    deleteTitular(user, user?.company, deal_id, arrayConsumidorFinalDelete);
    sendApoderado(user, user?.company, deal_id, arrayPersonaJuridica);
    deletePersonaJuridica(
      user,
      user?.company,
      deal_id,
      arrayPersonaJuridicaDelete
    );
    toast.success("Informacion Guardada con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };
  const captureType = (e) => {
    setHolderType(e.target.value);
  };

  const deleteFila = (indexItem) => {
    setarrayConsumidorFinalDelete((arrayTitularDelete) => [
      ...arrayTitularDelete,
      arrayConsumidorFinal.filter((todo, index) => index === indexItem)[0],
    ]);
    setarrayConsumidorFinal((prevState) =>
      prevState.filter((todo, index) => index !== indexItem)
    );
  };

  const deleteApoderado = (indexItem) => {
    setarrayPersonaJuridicaDelete((arrayPersonaJuridicaDelete) => [
      ...arrayPersonaJuridicaDelete,
      arrayPersonaJuridica.filter((todo, index) => index === indexItem)[0],
    ]);
    setarrayPersonaJuridica((prevState) =>
      prevState.filter((todo, index) => index !== indexItem)
    );
  };

  const updateTitularFunction = (data) => {
    setfinalConsumerModalEdit(true);
    settitularEdit(data);
    settitularId(data.cuil_dni);
  };

  const updatePersonaJuridica = (data) => {
    setlegaEntityModalEdit(true);
    setapoderadoEdit(data);
    setapoderadoId(data.dni);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(updateInfoTake)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={textStyle} className="mb-2">
            Titulares
          </p>
          {!disabled ? (
            <Button type="submit" disabled={disabled}>
              Guardar cambios
            </Button>
          ) : null}
        </div>
        <Row>
          <Col lg={6}>
            <div className="select-container">
              <label className="label-input">Tipo de Inscripción</label>
              <Form.Select
                className="deal-select"
                disabled={disabled}
                {...register("tipo_inscripcion")}
                onChange={captureType}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>Consumidor final</option>
                <option>Persona juridica</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <>
          {holderType === "Consumidor final" ? (
            <Table
              bordered
              style={{ marginTop: "16px" }}
              className="discount-table"
            >
              <thead>
                <tr className="table-header">
                  <th>Nombre completo</th>
                  <th>DNI/CUIL</th>
                  <th>Estado Civil</th>
                  <th>Domicilio fiscal</th>
                  <th>Domicilio real del titular</th>
                  <th>Mail</th>
                  <th>Titularidad (%)</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    Nombre del conyugue{" "}
                    {
                      <Button
                        size="sm"
                        hidden={disabled}
                        onClick={() => {
                          setFinalConsumerModal(true);
                        }}
                        className="ms-1"
                      >
                        <MdAdd
                          style={{
                            fontSize: "22px",
                          }}
                        />
                      </Button>
                    }
                  </th>
                </tr>
              </thead>
              <FinalConsumerModalComponent
                modal={finalConsumerModal}
                deal_id={deal_id}
                setModal={setFinalConsumerModal}
                setarrayConsumidorFinal={setarrayConsumidorFinal}
              />
              <ModalEditFinalConsumerComponent
                modal={finalConsumerModalEdit}
                setModal={setfinalConsumerModalEdit}
                deal_id={deal_id}
                titularEdit={titularEdit}
                titularId={titularId}
                arrayConsumidorFinal={arrayConsumidorFinal}
              />
              <tbody>
                {arrayConsumidorFinal?.map((info, i) => (
                  <tr className="table-body" key={i}>
                    <td>{info.nombre_completo}</td>
                    <td>{info.cuil_dni}</td>
                    <td>{info.estado_civil}</td>
                    <td>{info.domicilio_fiscal}</td>
                    <td>{info.domicilio_real}</td>
                    <td>{info.mail}</td>
                    <td>{info.titularidad}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {info.conyuge}{" "}
                      {!disabled ? (
                        <>
                          <div>
                            <MdEdit
                              onClick={() => updateTitularFunction(info)}
                              style={{
                                fontSize: "24px",
                                color: colors.primary.primary,
                                cursor: "pointer",
                              }}
                            />
                            <RiDeleteBin6Fill
                              style={{
                                fontSize: "24px",
                                color: colors.status.red,
                                cursor: "pointer",
                              }}
                              onClick={() => deleteFila(i)}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <>
              {/* <form onSubmit={handleSubmit(updatePersonaJuridica)}> */}
              <Row>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"Nombre de la empresa"}
                    placeholder={dataEmpresa?.empresa}
                    type="text"
                    register={register}
                    disabled={disabled}
                    valueRegister={"empresa"}
                  />
                </Col>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"CUIT"}
                    placeholder={dataEmpresa?.cuit}
                    type="number"
                    disabled={disabled}
                    register={register}
                    valueRegister={"cuit"}
                  />
                </Col>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"Domicilio fiscal"}
                    placeholder={dataEmpresa?.domicilio_fiscal}
                    type="text"
                    register={register}
                    disabled={disabled}
                    valueRegister={"domicilio_fiscal"}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"Mail"}
                    placeholder={dataEmpresa?.mail}
                    type="email"
                    disabled={disabled}
                    register={register}
                    valueRegister={"mail"}
                  />
                </Col>
                <Col xs={6} lg={4}>
                  <label className="label-input">Empresa validada</label>
                  <Form.Select
                    className="deal-select mb-4"
                    disabled={disabled}
                    {...register("validada")}
                  >
                    <option value="" disabled hidden>
                      Seleccione una opción
                    </option>
                    <option>Si</option>
                    <option>No</option>
                  </Form.Select>
                </Col>
                <Col xs={6} lg={4}>
                  <label className="label-input">Tipo de empresa</label>
                  <Form.Select
                    className="deal-select"
                    disabled={disabled}
                    {...register("tipo_empresa")}
                  >
                    <option value="" disabled hidden>
                      Seleccione una opción
                    </option>
                    <option>Sociedad Anonima</option>
                    <option>SRL</option>
                  </Form.Select>
                </Col>
              </Row>
              {/* </form> */}
              <Table bordered className="discount-table">
                <thead>
                  <tr className="table-header">
                    <th>Apoderado</th>
                    <th
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      DNI apoderado{" "}
                      {
                        <Button
                          size="sm"
                          hidden={disabled}
                          onClick={() => {
                            setLegalEntityModal(true);
                          }}
                        >
                          <MdAdd
                            style={{
                              fontSize: "22px",
                            }}
                          />
                        </Button>
                      }
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <LegalEntityModalComponent
                    modal={legalEntityModal}
                    setModal={setLegalEntityModal}
                    deal_id={deal_id}
                    setarrayPersonaJuridica={setarrayPersonaJuridica}
                  />
                  <ModalEditLegalEntityComponent
                    modal={legaEntityModalEdit}
                    setModal={setlegaEntityModalEdit}
                    deal_id={deal_id}
                    apoderadoEdit={apoderadoEdit}
                    apoderadoId={apoderadoId}
                    arrayPersonaJuridica={arrayPersonaJuridica}
                  />
                  {arrayPersonaJuridica?.map((info, i) => (
                    <tr className="table-body" key={i}>
                      <td>{info.name}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {info.dni}{" "}
                        {!disabled ? (
                          <>
                            <div>
                              <MdEdit
                                onClick={() => updatePersonaJuridica(info)}
                                style={{
                                  fontSize: "24px",
                                  color: colors.primary.primary,
                                  cursor: "pointer",
                                }}
                              />
                              <RiDeleteBin6Fill
                                style={{
                                  fontSize: "24px",
                                  color: colors.status.red,
                                  cursor: "pointer",
                                }}
                                onClick={() => deleteApoderado(i)}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      </Form>
    </>
  );
}
