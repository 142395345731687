import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { MdAdd, MdEdit } from "react-icons/md";
import ModalAddOrEditDiscountsComponent from "./ModalAddOrEditDiscountsComponent";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useUser } from "../../../../context/UserContext";
import { useParams } from "react-router-dom";

import Swal from "sweetalert2";
import { deleteDiscount } from "../../services/TakeServices/AmountToPayServices";
import { colors } from "../../../../styles/colors";

export const BenefitsTabComponent = ({ disabled, takeData }) => {
  const [addOrEditDiscounts, setAddOrEditDiscounts] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const { user } = useUser();
  const params = useParams();

  const styledisabled = {
    backgroundColor: colors.gris[100],
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };
  const cellStyle = {
    color: colors.neutral.lightTwo,
  };

  function deleteDiscountsModal(data) {
    Swal.fire({
      title: "Borrar descuento",
      text: "¿Estas seguro de que quieres eliminar el descuento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        deleteDiscount(params.deal_id, data, user, true);
      } else {
        console.log("*NO se elimina el descuento*");
      }
    });
  }

  const editDiscounts = (data) => {
    setDataEdit(data);
    setAddOrEditDiscounts(true);
    setIsEditing(true);
  };

  //   console.log("dataEdit", dataEdit);

  return (
    <>
      <div style={{ width: "200px" }}>
        <p style={textStyle} className="mb-2">
          Oferta final
        </p>
        <Table bordered style={styledisabled}>
          <thead>
            <tr className="table-header">
              <th className="table-title">Oferta final</th>
              <th style={cellStyle} className="table-body">
                ${takeData?.benefits?.offer}
              </th>
            </tr>
          </thead>
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p style={textStyle}>Descuentos</p>
        {
          <Button
            size="sm"
            hidden={disabled}
            onClick={() => {
              setAddOrEditDiscounts(true);
              setIsEditing(false);
            }}
          >
            <MdAdd
              style={{
                fontSize: "18px",
              }}
            />
          </Button>
        }
      </div>
      <Table bordered style={styledisabled}>
        <thead>
          <tr
            className="table-header"
            style={{
              borderBottom: disabled
                ? "1px solid #1162A6"
                : "2px solid #864BFA",
            }}
          >
            {/* <th className="table-title">Oferta final</th> */}
            <th className="table-title">Descuentos</th>
            <th className="table-title">Beneficios</th>
          </tr>
        </thead>
        <tbody>
          {takeData?.benefits?.discounts?.map((data, i) => (
            <tr key={i}>
              {/* <td className="content-table"></td> */}
              <td className="content-table">{data?.discounts_benefits}</td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="content-table"
              >
                ${data.benefits}
                {!disabled ? (
                  <div>
                    <MdEdit
                      onClick={() => {
                        editDiscounts(data);
                      }}
                      style={{
                        fontSize: "24px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                    />
                    <RiDeleteBin6Fill
                      style={{
                        fontSize: "24px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                      onClick={() => deleteDiscountsModal(data)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ width: "200px" }}>
        <Table bordered style={styledisabled}>
          <thead>
            <tr className="table-header">
              <th className="table-title">Monto a pagar</th>
              <th style={cellStyle} className="table-body">
                ${takeData?.benefits?.total}
              </th>
            </tr>
          </thead>
        </Table>
      </div>
      <ModalAddOrEditDiscountsComponent
        modal={addOrEditDiscounts}
        setModal={setAddOrEditDiscounts}
        isEditing={isEditing}
        dataEdit={dataEdit}
        discounts={takeData?.benefits?.discounts}
        isBenefits={true}
      />
    </>
  );
};
