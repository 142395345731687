import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputComponent from "../../../../components/InputComponent";
import ToolTipComment from "./ToolTipComment";

const HolderComponentNew = ({
  disabled,
  register,
  setValue,
  dataHolder,
  textStyle,
}) => {
  const [holderType, setHolderType] = useState();

  useEffect(() => {
    setValue("tipo", dataHolder?.tipo);
    setHolderType(dataHolder?.tipo);
    setValue("conyuges", dataHolder?.conyuges);
    setValue("cantidad_titulares", dataHolder?.cantidad_titulares);
    setValue("tipo_empresa", dataHolder?.tipo_empresa);
  }, [dataHolder, setValue]);

  const captureType = (e) => {
    setHolderType(e.target.value);
  };

  return (
    <div>
      <p style={textStyle}>Titulares</p>
      <Row>
        <Col xs={6} lg={4}>
          <div className="select-container">
            <label className="label-input" htmlFor="tipo-select">Tipo</label>
            <Form.Select
              id="tipo-select"  // Asegurar que el id coincida con el atributo htmlFor
              className="deal-select"
              disabled={disabled}
              {...register("tipo")}
              onChange={captureType}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Empresa</option>
              <option>Particular</option>
            </Form.Select>
          </div>
        </Col>
        {holderType === "Particular" ? (
          <>
            <Col xs={6} lg={4}>
              <div className="select-container">
                <div className="d-flex align-items-center justify-content-between">
                  <label className="label-input" htmlFor="conyuges-select">Conyuges</label>
                  {dataHolder?.conyuges === "Si" ? (
                    <ToolTipComment data={dataHolder?.conyuges_comentario} />
                  ) : null}
                </div>
                <Form.Select
                  id="conyuges-select" // Asegurar que el id coincida con el atributo htmlFor
                  className="deal-select"
                  disabled={disabled}
                  {...register("conyuges")}
                >
                  <option value="" disabled hidden>
                    Seleccione una opción
                  </option>
                  <option>Si</option>
                  <option>No</option>
                </Form.Select>
              </div>
            </Col>
            <Col xs={6} lg={4}>
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input" htmlFor="cantidad-titulares-input">Cantidad de titulares</label>
                {Number(dataHolder?.cantidad_titulares) >= 2 ? (
                  <ToolTipComment data={dataHolder?.cantidad_titulares_comentario} />
                ) : null}
              </div>
              <InputComponent
                id="cantidad-titulares-input"  // Asegurar que el id coincida con el atributo htmlFor
                disabled={disabled}
                label={""}
                register={register}
                valueRegister={"cantidad_titulares"}
                type={"number"}
              />
            </Col>
          </>
        ) : holderType === "Empresa" ? (
          <Col xs={6} lg={4}>
            <div className="select-container">
              <label className="label-input" htmlFor="tipo-empresa-select">Tipo de empresa</label>
              <Form.Select
                id="tipo-empresa-select"  // Asegurar que el id coincida con el atributo htmlFor
                className="deal-select"
                disabled={disabled}
                {...register("tipo_empresa")}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>Sociedad Anonima</option>
                <option>SRL</option>
              </Form.Select>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default HolderComponentNew;
