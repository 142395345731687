import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { teams } from "../constants/teams";

function InitialRouteBasedOnRole() {
  const { user } = useUser();

  const roleRoutes = {
    [teams.ADMIN]: "/inspections",
    [teams.INSPECCION]: "/inspections",
    [teams.AS]: "/inspections",
    [teams.BACKOFFICE]: "/takes",
    [teams.CS]: "/tasks",
    [teams.PRICING]: "/tasks",
  };

  if (!user) return <Navigate to="/login" />;

  return <Navigate to={roleRoutes[user.team] || "/unauthorized"} />;
}

export default InitialRouteBasedOnRole;