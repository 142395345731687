import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SelectYesNoComponent from "../../../../components/SelectYesNoComponent";
import { updateDocumentation } from "../../services/TakeServices/DocumentationServices";
import { toast } from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import InputComponent from "../../../../components/InputComponent";

export default function DocumentationTabComponent({
  disabled,
  takeData,
  user,
  deal_id,
  toggleEditing,
  textStyle,
}) {
  const { register, handleSubmit, setValue } = useForm();
  const [valueCodigoRadio, setvalueCodigoRadio] = useState();
  const [valueCedulaVerde, setvalueCedulaVerde] = useState();
  const [valueCedulaAzul, setvalueCedulaAzul] = useState();
  const [valueVTV, setvalueVTV] = useState();
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    setValue("titulo_vehiculo", takeData?.titulo_vehiculo);
    setValue("ddjj_extravio", takeData?.ddjj_extravio);
    setValue("cedulas_verdes", takeData?.cedulas_verdes);
    setValue("value_cedulas_verdes", takeData?.value_cedulas_verdes);
    setvalueCedulaVerde(takeData?.cedulas_verdes);
    setValue("cedulas_azules", takeData?.cedulas_azules);
    setValue("value_cedulas_azules", takeData?.value_cedulas_azules);
    setvalueCedulaAzul(takeData?.cedulas_azules);
    setValue("ddjj_extravio_cedula_azul", takeData?.ddjj_extravio_cedula_azul);
    setValue("grabado_autopartes", takeData?.grabado_autopartes);
    setValue("grabado_cristales", takeData?.grabado_cristales);
    setValue("vpa", takeData?.vpa);
    setValue("vtv", takeData?.vtv);
    setSelectedDate(takeData?.vtv_expiration_date);
    setvalueVTV(takeData?.vtv);
    setValue("manual_auto", takeData?.manual_auto);
    setValue("llave_original", takeData?.llave_original);
    setValue("segunda_llave", takeData?.segunda_llave);
    setValue("gns", takeData?.gns);
    setValue("combrobantes_service", takeData?.combrobantes_service);
    setValue("codigo_radio_llaves", takeData?.codigo_radio_llaves);
    setValue("value_codigo_radio_llaves", takeData?.value_codigo_radio_llaves);
    setvalueCodigoRadio(takeData?.codigo_radio_llaves);
    setValue("tuerca_seguridad", takeData?.tuerca_seguridad);
    setValue("baja_municipal_provincial", takeData?.baja_municipal_provincial);
    setValue(
      "libra_deuda_municipal_provincial",
      takeData?.libra_deuda_municipal_provincial
    );
  }, [setValue, takeData]);

  const sendDocumentation = (data) => {
    updateDocumentation(data, deal_id, user);
    toast.success("Informacion Guardada con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };

  const handleInputChange = (e) => {
    setvalueCodigoRadio(e.target.value);
  };
  const handleInputChangeCedulaVerde = (e) => {
    setvalueCedulaVerde(e.target.value);
  };
  const handleInputChangeCedulaAzul = (e) => {
    setvalueCedulaAzul(e.target.value);
  };

  const handleInputChangeVTV = (e) => {
    setvalueVTV(e.target.value);
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(sendDocumentation)}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p style={textStyle}>Documentación</p>
          {!disabled ? (
            <Button type="submit" disabled={disabled}>
              Guardar cambios
            </Button>
          ) : null}
        </div>
        <Row>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Titulo del Vehiculo"}
              register={register}
              nameRegister={"titulo_vehiculo"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"DDJJ de Extravío"}
              register={register}
              nameRegister={"ddjj_extravio"}
            />
          </Col>
          <Col lg={valueCedulaVerde === "Si" ? 3 : 4}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input">
                  Cédula Verde de titular/es{" "}
                  {takeData?.value_cedulas_verdes
                    ? "(" + takeData?.value_cedulas_verdes + ")"
                    : null}
                </label>
              </div>
              <Form.Select
                className="deal-select"
                {...register("cedulas_verdes")}
                onChange={(e) => handleInputChangeCedulaVerde(e)}
                disabled={disabled}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>Si</option>
                <option>No</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <Row>
          {valueCedulaVerde === "Si" ? (
            <Col xs={6} lg={4}>
              <InputComponent
                disabled={disabled}
                label={"Cantidad"}
                placeholder="Inserte cantidad"
                type="number"
                register={register}
                valueRegister={"value_cedulas_verdes"}
              />
            </Col>
          ) : null}
          <Col lg={valueCedulaAzul === "Si" ? 3 : 4}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input">
                  Cédula Azul de titular/es{" "}
                  {takeData?.value_cedulas_azules
                    ? "(" + takeData?.value_cedulas_azules + ")"
                    : null}
                </label>
              </div>
              <Form.Select
                className="deal-select"
                {...register("cedulas_azules")}
                onChange={(e) => handleInputChangeCedulaAzul(e)}
                disabled={disabled}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>Si</option>
                <option>No</option>
              </Form.Select>
            </div>
          </Col>
          {valueCedulaAzul === "Si" ? (
            <Col xs={6} lg={4}>
              <InputComponent
                disabled={disabled}
                label={"Cantidad"}
                placeholder="Inserte cantidad"
                type="number"
                register={register}
                valueRegister={"value_cedulas_azules"}
              />
            </Col>
          ) : null}
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"DDJJ de Extravío Cedula Azul"}
              register={register}
              nameRegister={"ddjj_extravio_cedula_azul"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Grabado de Autopartes"}
              register={register}
              nameRegister={"grabado_autopartes"}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Grabado de Cristales "}
              register={register}
              nameRegister={"grabado_cristales"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"VPA"}
              register={register}
              nameRegister={"vpa"}
            />
          </Col>
          <Col lg={valueVTV === "Si" ? 3 : 4}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input">VTV</label>
              </div>
              <Form.Select
                className="deal-select"
                {...register("vtv")}
                onChange={(e) => handleInputChangeVTV(e)}
                disabled={disabled}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>Si</option>
                <option>No</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <Row>
          {valueVTV === "Si" ? (
            <Col xs={6} lg={4}>
              <div style={{ marginBottom: "12px", marginTop: "8px" }}>
                <label className="label-input">Fecha de Vencimiento</label>
                <input
                  disabled={disabled}
                  style={{ width: "100%" }}
                  className="input-deal"
                  type="date"
                  {...register("vtv_expiration_date")}
                  value={selectedDate}
                  onChange={handleDateChange}
                  // value="2017-06-01"
                />
              </div>
            </Col>
          ) : null}
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Manual del Auto"}
              register={register}
              nameRegister={"manual_auto"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Llave Original"}
              register={register}
              nameRegister={"llave_original"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Segunda Llave"}
              register={register}
              nameRegister={"segunda_llave"}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"GNC"}
              register={register}
              nameRegister={"gns"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Comprobante de Service"}
              register={register}
              nameRegister={"combrobantes_service"}
            />
          </Col>
          <Col lg={valueCodigoRadio === "Si" ? 3 : 4}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input">Código de Radio / Llaves</label>
              </div>
              <Form.Select
                className="deal-select"
                {...register("codigo_radio_llaves")}
                onChange={(e) => handleInputChange(e)}
                disabled={disabled}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>Si</option>
                <option>No</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <Row>
          {valueCodigoRadio === "Si" ? (
            <Col xs={6} lg={4}>
              <InputComponent
                disabled={disabled}
                label={"Código"}
                placeholder="Inserte código"
                type="number"
                register={register}
                valueRegister={"value_codigo_radio_llaves"}
              />
            </Col>
          ) : null}
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Tuercas de Seguridad"}
              register={register}
              nameRegister={"tuerca_seguridad"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Baja Municipal / Provincial"}
              register={register}
              nameRegister={"baja_municipal_provincial"}
            />
          </Col>
          <Col xs={6} lg={4}>
            <SelectYesNoComponent
              disabled={disabled}
              titleLabel={"Libre de Deuda Municipal / Provincial"}
              register={register}
              nameRegister={"libra_deuda_municipal_provincial"}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input">Ultimo service</label>
              </div>
              <Form.Select
                className="deal-select"
                {...register("ultimo_service")}
                disabled={disabled}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>No</option>
                <option>Si</option>
              </Form.Select>
            </div>
          </Col>
          <Col xs={6} lg={4}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input">Copia del seguro</label>
              </div>
              <Form.Select
                className="deal-select"
                {...register("copia_seguro")}
                disabled={disabled}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>No</option>
                <option>Si</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
}
