import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';

const userContext = createContext();
export const useUser = () => {
  const context = useContext(userContext);
  if (!context) throw new Error("There is no User provider");
  return context;
};

export function UserProvider({ children }) {
  const { userLoggedIn } = useAuth();
  const [user, setUser] = useState(userLoggedIn || JSON.parse(localStorage.getItem("userLoggedIn")));
  const [userCompany, setUserCompany] = useState(localStorage.getItem("loginCompany") || user?.company);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [error, setError] = useState(null);

  // Sincronizar cambios cuando `userLoggedIn` cambie
  useEffect(() => {
    if (userLoggedIn) {
      setUser(userLoggedIn);
      setUserCompany(userLoggedIn.company);
      localStorage.setItem("userLoggedIn", JSON.stringify(userLoggedIn));
      localStorage.setItem("loginCompany", userLoggedIn.company);
      setLoadingCompany(false);
    } else {
      setUser(null);
      setUserCompany(null);
      setLoadingCompany(true);
    }
  }, [userLoggedIn]);

  return (
    <userContext.Provider
      value={{
        user,
        userCompany,
        loadingCompany,
        error,
        setError,
      }}
    >
      {children}
    </userContext.Provider>
  );
}
