import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import SpinnerFullScreenComponent from "../components/SpinnerFullScreenComponent";
import ErrorScreenComponent from "../components/ErrorScreenComponent";

export function ProtectedRoute({ children, requiredRoles }) {
  const { userLoggedIn, loading, error } = useAuth();

  if (loading) return <SpinnerFullScreenComponent />;
  if (error) return <ErrorScreenComponent />;

  if (!userLoggedIn) return <Navigate to="/login" />;
  if (requiredRoles && !requiredRoles.includes(userLoggedIn.team)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
}
