import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { postLoadPhotos, getPhotos, deleteCarPhoto } from "../../../services/InspectionServices/secctionsInspectionServices";
import { useParams } from "react-router-dom";
import { colors } from "../../../../../styles/colors";
import { EmptyStateComponent } from "../../../../../components";
import toast from "react-hot-toast";

const DragAndDropInspComponent = ({ editAllowed }) => {
  const { deal_id } = useParams();

  const [dataFiles, setDataFiles] = useState([]);
  const fetchPhotos = async () => {
    const response = await getPhotos({ deal_id, company: 'san_jorge' });
    if (!response.error) {
      setDataFiles(response);
    }
  };
  useEffect(() => {
    fetchPhotos();
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const droppedFiles = [...e.dataTransfer.files];
    await uploadFiles(droppedFiles);
  };

  const sendFiles = async (e) => {
    const selectedFiles = [...e.target.files];
    await uploadFiles(selectedFiles);
  };

  const MAX_SIZE_MB = 32000;

  const uploadFiles = async (files) => {
    let currentBatch = [];
    let currentBatchSize = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSize = file.size;

      if (currentBatchSize + fileSize > MAX_SIZE_MB * 1000) {
        await sendBatch(currentBatch);

        currentBatch = [file];
        currentBatchSize = fileSize;
      } else {
        currentBatch.push(file);
        currentBatchSize += fileSize;
      }
    }

    if (currentBatch.length > 0) {
      await sendBatch(currentBatch);
    }
  };

  const sendBatch = async (filesBatch) => {
    const response = await postLoadPhotos({ deal_id, company: 'san_jorge', files: filesBatch });

    if (!response.error) {
      toast.success("Archivo guardado", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      fetchPhotos();
    } else {
      console.error("Error al guardar el archivo:", response.error);
      toast.error("Error al guardar el archivo.", {
        style: {
          color: colors.text.primary,
          background: colors.error.dark,
        },
      });
    }
  };

  const handleRemoveFile = async (file) => {
    const response = await deleteCarPhoto({ deal_id, company: 'san_jorge', photo_url: file });
    if (!response.error) {
      toast.success("Archivo eliminado con éxito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      setDataFiles((state) => state.filter((item) => item !== file));
    } else {
      console.error("Error al borrar el archivo:", response.error);
      toast.error("Error al borrar el archivo.", {
        style: {
          color: colors.text.primary,
          background: colors.error.dark,
        },
      });
    }
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  return (
    <>
      {editAllowed ? (
        <>
          <p style={textStyle}>Archivos Adjuntos</p>
          <Row>
            <div
              className="mt-2 drag-and-drop-zone d-flex align-items-center justify-content-center"
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <p style={{ margin: "0px" }}>
                Arrastra archivos aquí o{" "}
                <label
                  htmlFor="upload-img"
                  className="attch-input"
                  style={{ marginTop: "0px" }}
                >
                  <span className="attach-icon-text">
                    selecciona el archivo desde tu computadora
                  </span>
                </label>
                <input
                  type="file"
                  id="upload-img"
                  multiple={true}
                  onChange={sendFiles}
                  style={{ display: "none" }}
                />{" "}
              </p>
            </div>
          </Row>
          {dataFiles.length === 0 ? (
            <EmptyStateComponent />
          ) : (
            <Row>
              <div className="d-flex flex-wrap">
                {dataFiles?.map((file, i) => (
                  <div key={i} className="position-relative m-2">
                    <img src={file}
                      alt={'file'}
                      onClick={() => window.open(file, '_blank')}
                      style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: '6px', cursor: "pointer" }} />
                    <RiDeleteBin6Fill
                      className="delete-attach-icon"
                      style={{ position: "absolute", bottom: "5px", right: "5px", cursor: "pointer" }}
                      onClick={() => handleRemoveFile(file)}
                    />
                  </div>
                ))}
              </div>
            </Row>
          )}
        </>
      ) : (
        <>
          <p style={textStyle}>Archivos Adjuntos</p>
          {dataFiles?.length > 0 ? (
            <Row>
              <div className="d-flex flex-wrap">
                {dataFiles?.map((file, i) => (
                  <div key={i} className="position-relative m-2">
                    <img
                      src={file}
                      alt={'file'}
                      style={{ width: "100px", height: "100px", objectFit: "cover", cursor: "pointer" }}
                      onClick={() => window.open(file, '_blank')}
                    />
                  </div>
                ))}
              </div>
            </Row>
          ) : (
            <EmptyStateComponent />
          )}
        </>
      )}
    </>
  );
};


export default DragAndDropInspComponent;