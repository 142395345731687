import React from "react";
import { Row } from "react-bootstrap";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { uploadFile } from "../firebase/config";
import { generateId } from "../utils/IdGenerator";
import EmptyStateComponent from "./EmptyStateComponent";
import { HiOutlineDownload } from "react-icons/hi";
import { MdRemoveRedEye } from "react-icons/md";
import { colors } from "../styles/colors";

const DragAndDropComponent = ({ dataFiles, setDataFiles, disabled }) => {
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = [...e.dataTransfer.files];
    Array.from(droppedFiles).forEach(async (file) => {
      const result = await uploadFile(file);
      setDataFiles((state) => [
        ...state,
        {
          name: file.name,
          size: file.size,
          urlFirestore: result,
          url: URL.createObjectURL(file),
          id: generateId(),
          type: file.type,
        },
      ]);
    });
  };

  const sendFiles = async (e) => {
    const selectedFiles = [...e.target.files];
    Array.from(selectedFiles).forEach(async (file) => {
      const result = await uploadFile(file);
      setDataFiles((state) => [
        ...state,
        {
          name: file.name,
          size: file.size,
          urlFirestore: result,
          url: URL.createObjectURL(file),
          id: generateId(),
          type: file.type,
        },
      ]);
    });
  };

  const handledRemoveFiles = (data) => {
    setDataFiles((state) => state.filter((item) => item.url !== data.url));
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  return (
    <>
      {!disabled ? (
        <>
          <p style={textStyle}>Archivos Adjuntos</p>
          <Row>
            <div
              className="mt-2 drag-and-drop-zone d-flex align-items-center justify-content-center"
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <p style={{ margin: "0px" }}>
                Arrastra archivos aquí o{" "}
                <label
                  htmlFor="upload-img"
                  className="attch-input"
                  style={{ marginTop: "0px" }}
                >
                  <span className="attach-icon-text">
                    selecciona el archivo desde tu computadora
                  </span>
                </label>
                <input
                  type="file"
                  id="upload-img"
                  multiple={true}
                  onChange={sendFiles}
                  style={{ display: "none" }}
                />{" "}
              </p>
            </div>
          </Row>
          <Row>
            <ul className="mt-4">
              {dataFiles?.map((img, i) => (
                <div key={i}>
                  <div className="d-flex align-items-center justify-content-between">
                    <li className="item-list-img">{img.name}</li>
                    <span className="d-flex align-items-center">
                      <p className="attach-text-kb">
                        {img.size}
                        {" kb"}
                      </p>
                      <RiDeleteBin6Fill
                        className="delete-attach-icon mt-1"
                        onClick={() => {
                          handledRemoveFiles(img);
                        }}
                      />
                    </span>
                  </div>
                </div>
              ))}
            </ul>
          </Row>
        </>
      ) : (
        <>
          <p style={textStyle}>Archivos Adjuntos</p>
          {dataFiles?.length > 0 ? (
            <Row>
              <ul>
                {dataFiles?.map((img, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <li className="item-list-img mt-1 mb-1">{img.name}</li>
                      <div>
                        <a
                          href={`${img.url}`}
                          download={img.name}
                          rel="noreferrer"
                        >
                          <HiOutlineDownload
                            className="ms-2 me-2"
                            fontSize="22px"
                          />
                        </a>
                        <a
                          href={img.urlFirestore}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MdRemoveRedEye fontSize={24} />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </ul>
            </Row>
          ) : (
            <EmptyStateComponent />
          )}
        </>
      )}
    </>
  );
};
export default DragAndDropComponent;
