import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import DealQuotesModalComponent from "./DealQuotesModalComponent";
import { teams } from "../../../../constants/teams";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import {
  SmallSpinnerComponent,
  StatusIconComponent,
} from "../../../../components";
import { getStatusColor } from "../../../../utils/StatusColor";

export default function DealQuotesCardSectionComponent({ user, isPageWide }) {
  const { deal_id } = useParams();
  const { data, loading } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "pricing"
  );
  const [showPricingModal, setShowPricingModal] = useState(false);

  const handleClose = () => setShowPricingModal(false);
  const handleShow = () => setShowPricingModal(true);

  const [latestDate, setLatestDate] = useState(null);
  const [latestValue, setLatestValue] = useState(null);
  const [latestKey, setLatestKey] = useState(null);
  const [latestStatus, setLatestStatus] = useState(null);

  useEffect(() => {
    const findLatestDate = () => {
      let latest = null;
      let latestDate = null;
      let latestValue = "00.000.000";
      let latestKey = null;
      let latestStatus = null;

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const item = data[key];
          const dateValue = item.date;

          // Solo considera elementos con un valor y una fecha válidos
          if (!item.value || !dateValue) {
            continue;
          }

          let currentDate;
          if (typeof dateValue === "string") {
            currentDate = new Date(dateValue);
          } else if (dateValue && dateValue.seconds) {
            currentDate = new Date(dateValue.seconds * 1000);
          } else {
            console.error(`Fecha no válida para la clave ${key}`);
            continue;
          }

          if (!isNaN(currentDate)) {
            // Verifica si la conversión a objeto de fecha fue exitosa
            if (!latest || currentDate > latest) {
              latest = currentDate;
              latestDate = currentDate;
              latestValue = item.value;
              latestKey = key;
              latestStatus = item.status;
            }
          }
        }
      }

      // Solo actualiza el estado una vez, después de completar el bucle
      setLatestDate(latestDate);
      setLatestValue(latestValue);
      setLatestKey(latestKey);
      setLatestStatus(latestStatus);
    };

    if (data) {
      findLatestDate();
    } else {
      // Resetea los valores si no hay datos
      setLatestDate(null);
      setLatestValue(null);
      setLatestKey(null);
      setLatestStatus(null);
    }
  }, [data, deal_id]); // Ejecuta el efecto cuando cambian los datos o el deal_id

  function getSpecificWord(text) {
    const keywordMapping = {
      expected_offer: "Oferta esperada",
      final_offer: "Oferta final",
      offer: "Oferta inicial",
      range: "Rango inicial",
      sale_price: "Precio de venta",
      virtual_offer: "Oferta Virtual",
    };
    return keywordMapping[text] || false;
  }

  const isRange = latestKey === "range";

  return (
    <div className="container-card-deal-layout vertical-divider">
      {loading ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          <div className="column-data">
            {latestKey ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="offer-type"
                    style={{
                      color: getStatusColor(latestStatus),
                    }}
                  >
                    {getSpecificWord(latestKey)}
                  </p>
                  <StatusIconComponent status={latestStatus} />
                </div>
                <p
                  className={`offer-value ${isRange ? "range-font-size" : ""} ${isPageWide ? "" : "range-font-size-mobile"
                    }`}
                  style={{
                    color: getStatusColor(latestStatus),
                  }}
                >
                  {latestKey !== "range"
                    ? latestValue
                      ? latestValue.startsWith("$")
                        ? latestValue
                        : `$${latestValue}`
                      : ""
                    : latestValue}
                </p>
                <p className="offer-date">
                  Última cotización{" "}
                  {latestDate
                    ? latestDate.toLocaleDateString("es-AR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                    : "N/A"}
                </p>
              </>
            ) : (
              <p
                className="pending-offer"
                style={{
                  color: getStatusColor("requested"),
                  alignSelf: "center",
                }}
              >
                Cotización Pendiente
              </p>
            )}
          </div>
          <div className="columm-buttons">
            <Button
              variant={`${showPricingModal ? "primary" : "outline-primary"}`}
              className={`${isPageWide ? "" : "font-size-mobile"}`}
              onClick={handleShow}
            >
              Cotizaciones
            </Button>
            {user?.team === teams.PRICING || user?.team === teams.ADMIN ? (
              <Button
                style={{ marginTop: "8px" }}
                className={`${isPageWide ? "" : "font-size-mobile"}`}
                as={NavLink}
                to={`analitics/${deal_id}`}
                variant="outline-primary"
              >
                Analíticas
              </Button>
            ) : null}
          </div>
          <DealQuotesModalComponent
            showPricingModal={showPricingModal}
            handleClose={handleClose}
            data={data}
            loading={loading}
            latestKey={latestKey}
          />
        </>
      )}
    </div>
  );
}
