import { useState, useCallback, useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const channelRef = useRef(null); // Usar ref para gestionar el canal

  // Inicializa el canal solo si no existe
  const initializeChannel = () => {
    if (!channelRef.current) {
      channelRef.current = new BroadcastChannel('idle-timer');
    }
  };

  // Maneja el evento de inactividad
  const handleIdle = useCallback(() => {
    setIdle(true);
    onIdle();

    if (channelRef.current) {
      channelRef.current.postMessage({ type: 'idle' });
    }
  }, [onIdle]);

  // Maneja el evento de reactivación
  const handleActive = useCallback(() => {
    setIdle(false);

    if (channelRef.current) {
      channelRef.current.postMessage({ type: 'active' });
    }
  }, []);

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    onActive: handleActive,
    debounce: 500,
  });

  useEffect(() => {
    initializeChannel(); // Asegura que el canal esté listo

    const channel = channelRef.current;

    if (channel) {
      channel.onmessage = (event) => {
        const { type } = event.data;
        if (type === 'active') {
          idleTimer.reset();
        }
      };
    }

    return () => {
      if (channel) {
        channel.close();
        channelRef.current = null;
      }
    };
  }, [idleTimer]);

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};

export default useIdleTimeout;
